<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" @refresh="onRefresh">
        <b-button variant="label-success" size="sm" slot="action" :to="{name: `article.home`}" class="border-0 btn-icon-md ">
          {{ $t("COMMON.BACK") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="6" md="6" v-for="(version, index) in versions" :key="version.id">
          <raise-item v-if="type === 'raise'" :index="index" :versions="versions" :limit="versions.length" :raise="version" :article="article" @loading="onLoading" @loaded="onLoaded" />
          <project-item v-if="type === 'project'" :index="index" :versions="versions" :limit="versions.length" :project="version" :article="article" @loading="onLoading" @loaded="onLoaded" />
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import RaiseItem from "@v@/components/items/article/VersionItem/Raise";
  import ProjectItem from "@v@/components/items/article/VersionItem/Project";
  import pagination from "@v@/components/paginate";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import articleMixin from "@m@/article.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, pagination, RaiseItem, ProjectItem },
    mixins: [articleMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {
      id: {
        type: [Number, String]
      },
      type: {
        type: String,
        default: ()=> 'raise'
      }
    },
    created() {
      this.onRefresh();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'article.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    watch: {},
    computed: {
      ...mapGetters([
         'articleVersion'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
      versions(){
        const { type } = this.articleVersion || {};
        switch (type) {
          case 'raise':
            const { raises } = this.articleVersion || {};
            return raises || [];
          case 'project':
            const { projects } = this.articleVersion || {};
            return projects || [];
        }
        return [];
      },
      article(){
        let article = Object.assign({}, JSON.parse(JSON.stringify(this.articleVersion)));
        delete article.raises;
        delete article.projects;
        return article;
      },
    },
    data(){
      return {
        loading: false,
      }
    },
    methods: {
      onRefresh(){
        this.loading = true;
        this.getArticleVersion(this.id)
          .finally(()=>{
            this.loading = false;
          })
      },
      onLoading(){
        this.loading = true;
      },
      onLoaded(){
        this.loading = false;
        this.getArticleVersion(this.id);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
