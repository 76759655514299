<template>
  <portlet title=" " fluid-height head-no-border>
    <template v-slot:toolbar v-if="editable || versionable || copyable">
      <b-dropdown no-caret size="sm" variant="label-success" class="" toggle-class="btn-upper btn-icon btn-icon-md btn-pill">
        <template v-slot:button-content>
          <i class="flaticon-more-1"></i>
        </template>
        <b-dropdown-item v-if="editable" :to="{name: 'article.edit', params: {id: article.id, rid: raise.id}}"><i class="flaticon-list" style="padding-inline-end: 3px"></i><span class="dropdown-action-title">{{ $t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.ACTIONS.EDIT') }}</span></b-dropdown-item>
        <b-dropdown-item v-if="copyable" @click="onClickCopy"><i class="fa fa-copy" style="padding-inline-end: 3px"></i><span class="dropdown-action-title">{{ $t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.ACTIONS.COPY') }}</span></b-dropdown-item>
        <b-dropdown-divider v-if="versionable && editable" />
        <b-dropdown-item v-if="versionable" @click="onClickBeOnline"><i class="flaticon2-world" style="padding-inline-end: 3px"></i><span class="dropdown-action-title">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.ACTIONS.ONLINE") }}</span></b-dropdown-item>
      </b-dropdown>
    </template>
    <template v-slot:body>
      <div class="kt-widget kt-widget--user-profile-2">
        <div class="kt-widget__head">
          <div class="kt-widget__media">
            <b-avatar size="50" rounded class="kt-widget__img" text="!" :src="previewImage" />
          </div>
          <div class="kt-widget__info">
            <a href="#" class="kt-widget__username">
              {{ title }}
            </a>
            <span class="kt-widget__desc kt-font-warning kt-font-sm" v-if="pendingOnline">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.INFO.PENDING_ONLINE") }}</span>
            <span class="kt-widget__desc kt-font-success kt-font-sm" v-else-if="online">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.INFO.CURRENT_ONLINE") }}</span>
            <span class="kt-widget__desc kt-font-brand kt-font-sm" v-else-if="archived">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.INFO.ARCHIVED") }}</span>
          </div>
        </div>
        <div class="kt-widget__body">
          <div class="kt-widget__section">{{ describe }}</div>
          <div class="kt-widget__item">
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.FULL_NAME") }}</span>
              <span class="kt-widget__data cursor-default">{{ fullName }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.PHONE") }}</span>
              <span class="kt-widget__data cursor-default">{{ patient.phone }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.ID_CARD") }}</span>
              <span class="kt-widget__data cursor-default">{{ patient.id_card }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.PATIENT_ADDRESS") }}</span>
              <span class="kt-widget__data cursor-default">{{ patientAddress }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.HOSPITAL") }}</span>
              <span class="kt-widget__data cursor-default">{{ hospitalTitle }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.DEPARTMENT") }}</span>
              <span class="kt-widget__data cursor-default">{{ departmentTitle }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.HOSPITAL_ADDRESS") }}</span>
              <span class="kt-widget__data cursor-default">{{ hospitalAddress }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.RAISE_ADDRESS") }}</span>
              <span class="kt-widget__data cursor-default">{{ raiseHospitalAddress }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.PATIENT_ID") }}</span>
              <span class="kt-widget__data cursor-default">{{ raise.hospital.patient_id }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.RAISE") }}</span>
              <span class="kt-widget__data cursor-default">{{ raise.raise }}</span>
            </div>
            <div class="kt-widget__contact">
              <span class="kt-widget__label cursor-default">{{ $t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.CREATED_AT") }}</span>
              <span class="kt-widget__data cursor-default" :class="[direction_ltr]">{{ raise.created_at }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-flag position-absolute d-flex align-items-center justify-content-center">
        <i class="fa fa-info-circle kt-font-warning" v-if="pendingOnline"></i>
        <i class="fab fa-internet-explorer kt-font-success" v-else-if="online"></i>
        <i class="fa fa-lock kt-font-info" v-else-if="archived"></i>
      </div>
    </template>
  </portlet>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import directionMixin from "@m@/common/direction.mixin";
  import articleMixin from "@m@/article.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  export default {
    name: "RaiseItem",
    components: {Portlet},
    props: {
      raise: {
        type: Object,
        default: ()=> {return {}}
      },
      article: {
        type: Object,
        default: ()=> {return {}}
      },
      limit: {
        type: [String, Number],
        default: ()=> 1
      },
      index: {
        type: Number
      },
      versions: {
        type: Array,
        default: ()=> []
      }
    },
    mixins: [directionMixin, articleMixin, msgBoxConfirmMixin],
    computed: {
      lang(){
        return this.$i18n.locale;
      },
      title(){
        let { lang } = this;
        let { title } = this.raise || {};
        return title[lang] && title[lang].title || this.$t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.NO_TITLE');
      },
      previewImage(){
        let { cover_image_preview } = this.article || {};
        return cover_image_preview ? cover_image_preview : ''
      },
      describe(){
        let { lang } = this;
        let { title } = this.raise || {};
        return title[lang] && title[lang].describe || this.$t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.INFO.DESC');
      },
      fullName(){
        let { lang, patient } = this;
        return patient[lang] && patient[lang].full_name || this.$t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.LABEL.NO_FULL_NAME');
      },
      patient(){
        let { patient } = this.raise || {};
        return patient
      },
      patientAddress(){
        let { other } = this.patient;
        if( ! other ){
          other = {address: {"zh-UG": 'تولدۇرۇلمىغان', "zh-CN": '暂无'}}
        }
        let { address = {"zh-UG": 'تولدۇرۇلمىغان', "zh-CN": '暂无'} } = other;
        const { lang } = this;
        return address[lang]
      },
      onlineVersion(){
        return this.article.version || 0;
      },
      version(){
        return this.raise.version;
      },
      editable(){
        let { version, onlineVersion } = this;
        return onlineVersion < version;
      },
      versionable(){
        let { version, onlineVersion } = this;
        return onlineVersion !== version && version !== 1
      },
      copyable(){
        let { version, onlineVersion, limit, audit, index } = this;
        if( ! audit ){
          return onlineVersion === version && limit === 1;
        }else if( audit ){
          return onlineVersion === version && index === 0;
        }

      },
      audit(){
        return this.article.audit || 0;
      },
      pendingOnline(){
        let { audit, onlineVersion, version, limit } = this;
        if( ! audit ){
          return onlineVersion === version && limit === 1 || onlineVersion < version || onlineVersion > version
        }else {
          return onlineVersion < version
        }
        return !audit && onlineVersion === version || !audit && onlineVersion < version || !audit && onlineVersion > version || audit && onlineVersion < version
      },
      archived(){
        let { audit, onlineVersion, version, limit } = this;
        if( ! audit ){
          return onlineVersion > version || limit > 1
        }else {
          return onlineVersion > version
        }
        return !audit && onlineVersion > version || audit && onlineVersion > version
      },
      online(){
        let { audit, onlineVersion, version } = this;
        return audit && onlineVersion === version
      },
      hospital(){
        let { hospital: { hospital } } = this.raise;
        return hospital || {}
      },
      hospitalTitle(){
        let { lang, hospital } = this;
        return hospital[lang] && hospital[lang].title || '';
      },
      hospitalAddress(){
        let { lang, hospital } = this;
        return hospital[lang] && hospital[lang].address || '';
      },
      department(){
        let { hospital: { department } } = this.raise;
        return department || {}
      },
      departmentTitle(){
        let { lang, department } = this;
        return department[lang] && department[lang].title || '';
      },
      raiseHospitalAddress(){
        let { hospital } = this.raise;
        let { lang } = this;
        return hospital[lang] && hospital[lang].address || '';
      },
      hasPrevious(){
        const { index, versions } = this;

      },
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      onClickBeOnline(){
        this.msgBoxConfirm({
          message: this.$t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.TIPS.ONLINE"),
          confirmCallback: confirm=> {
            if( confirm ){
              this.setArticleOnline(this.article.id, this.raise.version)
            }
          }
        })
      },
      onClickCopy(){
        this.msgBoxConfirm({
          message: this.$t("COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.TIPS.COPY"),
          confirmCallback: confirm=> {
            if( confirm ){
              const {id: rid} = this.raise;
              const {id: aid, type} = this.article;
              this.makeCopyVersion(rid, aid)
                .then(res=>{
                  const { id, article_id } = res.data;
                  this.$router.push({ name: "article.edit", params: {id: article_id, rid: id, type }});
                })
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.kt-widget {
  .kt-widget__head {
    margin-top: -64px;
  }
}

.dropdown-action-title {
  padding-block-start: 2px;
}

  .footer-flag {
    z-index: 0;
    inset-inline: 0;
    inset-block: 0;
    pointer-events: none;
    backface-visibility: hidden;
    perspective: 2000px;
    .fa, .fab {
      font-size: 100px;
      opacity: .15;
    }
  }
</style>
