<template>
  <b-overlay :show="chartBusy">
  <portlet
          :title="$t('COMPONENTS.ITEMS.WIDGETS.ARTICLE_STATISTICS.TITLE')"
          head-class="kt-portlet__space-x"
          head-no-border
          body-fluid
          class="kt-portlet--fit"
  >
    <template v-slot:toolbar>
      <button @click="onClickRefreshStatistics" v-b-tooltip.hover  :title="$t('COMMON.REFRESH')" class="btn btn-clean btn-sm btn-icon btn-icon-md">
        <i class="flaticon2-refresh-arrow"></i>
      </button>
    </template>
    <template v-slot:body>
      <div class="kt-widget20">
          <div class="kt-widget20__content kt-portlet__space-x">
            <span class="kt-widget20__number kt-font-brand">{{ total_fee }} {{ $t('COMMON.$') }}</span>
            <span class="kt-widget20__desc">{{ total_fee_desc }}</span>
          </div>
          <div class="kt-widget20__chart">
            <Chart1 ref="chart" v-bind:options="chartOptions"></Chart1>
          </div>
      </div>
    </template>
  </portlet>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import Chart1 from "@/views/partials/widgets/Chart1.vue";
import Portlet from "@v@/partials/content/Portlet";
import formBusyMixin from "@m@/common/formBusy.mixin";
import articleMixin from "@m@/article.mixin";

export default {
  name: "ArticleStatistics",
  components: {
    Chart1,
    Portlet
  },
  mixins: [formBusyMixin, articleMixin],
  data() {
    return {
      chartOptions: {},
      chartBusy: false,
      total_fee: 0,
      total_fee_desc: this.$t('COMPONENTS.ITEMS.WIDGETS.ARTICLE_STATISTICS.LABEL.total_fee_desc'),
      timer: null,
    };
  },
  props: {
    id: {
      type: [Number, String]
    }
  },
  created() {
    this.getArticleStatisticsSync();
  },
  mounted() {
    this.timer = setInterval(()=>{
      this.getArticleStatisticsSync(false);
      this.$emit('refresh')
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
    formatChartData(statisticsData){
      let labels = [];
      let data = [];
      let total_fee = 0;
      for ( let item of statisticsData ){
        labels.push(item.date);
        data.push(item.total_fee);
        total_fee += parseFloat(item.total_fee);
      }
      const ctx = this.$refs["chart"].$el.getContext("2d");
      let gradient = ctx.createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(
        0,
        Chart.helpers
          .color("#d1f1ec")
          .alpha(1)
          .rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers
          .color("#d1f1ec")
          .alpha(0.3)
          .rgbString()
      );
      const defaults = {
        data: {
          labels,
          datasets: [
            {
              label: this.$t('COMPONENTS.ITEMS.WIDGETS.ARTICLE_STATISTICS.LABEL.$'),
              backgroundColor: gradient,
              borderColor: this.layoutConfig("colors.state.success"),
              pointBackgroundColor: Chart.helpers
                      .color("#000000")
                      .alpha(0)
                      .rgbString(),
              pointBorderColor: Chart.helpers
                      .color("#000000")
                      .alpha(0)
                      .rgbString(),
              pointHoverBackgroundColor: this.layoutConfig("colors.state.success"),
              pointHoverBorderColor: Chart.helpers
                      .color("#000000")
                      .alpha(0.1)
                      .rgbString(),
              data
            }
          ]
        }
      };
      this.chartOptions = Object.assign({}, defaults, this.chartOptions);
      this.total_fee = parseFloat(total_fee).toFixed(2);
    },
    getArticleStatisticsSync(busy = true){
      this.getArticleStatistics(this.id, {
        begin: ()=>{
          busy && this.showBusy('chartBusy');
        },
        after: (response, state)=>{
          let { data } = response;
          this.formatChartData(data);
          busy && this.hideBusy('chartBusy');
        },
      })
    },
    onClickRefreshStatistics(){
      this.getArticleStatisticsSync();
      this.$emit('refresh')
    },
  }
};
</script>
