<template>
  <div class="kt-widget24 kt-widget24--solid my-3">
    <draggable v-model="content" @start="drag=true" @end="drag=false">
      <b-row v-for="(model, index) in content" class="align-items-center my-3" :key="`model-${model.type}-${model.id}`">
        <template v-if="model.type === 'text' && only.includes('text')">
          <b-col md="10" sm="6">
            <b-form-group :id="`input-group-text-${model.id}`" :label="$t('COMPONENTS.SIMPLE_EDITOR.LABEL.text')" :label-for="`text_${model.id}`" >
              <b-form-textarea max-rows="10" :class="[direction]" :id="`text_${model.id}`" v-model="model.content" type="text" :placeholder="$t('COMPONENTS.SIMPLE_EDITOR.PLACEHOLDER.text')" />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-button class="btn-upper btn-icon btn-icon-md btn-pill" variant="danger" @click="deleteItem(index)"><i class="fa fa-trash-alt"></i></b-button>
          </b-col>
        </template>
        <template v-if="model.type === 'image' && only.includes('image')">
          <b-col md="10" sm="6">
            <b-form-group :id="`input-group-image-${model.id}`" :label="$t('COMPONENTS.SIMPLE_EDITOR.LABEL.image')" :label-for="`image_${model.id}`" :description="$t('COMPONENTS.SIMPLE_EDITOR.DESCRIPTION.image')" >
              <multi-uploader :id="`image_${model.id}`" v-model="model.src" :limit="5" :data="{strategy: 'editor'}" />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-button class="btn-upper btn-icon btn-icon-md btn-pill" variant="danger" @click="deleteItem(index)"><i class="fa fa-trash-alt"></i></b-button>
          </b-col>
        </template>
        <template v-if="model.type === 'voice' && only.includes('voice')">
          <b-col md="10" sm="6">
            <b-form-group :id="`input-group-voice-${model.id}`" :label="$t('COMPONENTS.SIMPLE_EDITOR.LABEL.voice')" :label-for="`voice_${model.id}`" >
              <b-input-group append="URL">
                <b-form-input :id="`voice_${model.id}`" class="direction-rtl" v-model="model.src" type="text" :placeholder="$t('COMPONENTS.SIMPLE_EDITOR.PLACEHOLDER.voice')" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-button class="btn-upper btn-icon btn-icon-md btn-pill" variant="danger" @click="deleteItem(index)"><i class="fa fa-trash-alt"></i></b-button>
          </b-col>
        </template>
        <template v-if="model.type === 'video' && only.includes('video')">
          <b-col md="10" sm="6">
            <b-form-group :id="`input-group-voice-${model.id}`" :label="$t('COMPONENTS.SIMPLE_EDITOR.LABEL.video')" :label-for="`video_${model.id}`" >
              <b-input-group append="URL">
                <b-form-input class="direction-rtl" :id="`video_${model.id}`" v-model="model.src" type="text" :placeholder="$t('COMPONENTS.SIMPLE_EDITOR.PLACEHOLDER.video')" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-button class="btn-upper btn-icon btn-icon-md btn-pill" variant="danger" @click="deleteItem(index)"><i class="fa fa-trash-alt"></i></b-button>
          </b-col>
        </template>
      </b-row>
    </draggable>
    <b-row class="my-3">
      <b-col class="d-flex flex-column align-items-center">
        <b-button-group>
          <template v-for="t in modelTypes">
            <b-button :key="t.type" v-if="only.includes(t.type)" :variant="t.variant"  @click="onClickMaker(t.type)" class="d-flex flex-column align-items-center align-content-center" >
              <i :class="t.icon" class="mb-3"></i>
              <span>{{ t.title }}</span>
            </b-button>
          </template>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="debug" >
      <b-col>
        <b-jumbotron :class="[direction]">
          <pre class="direction-ltr">{{ content }}</pre>
        </b-jumbotron>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import MultiUploader from "@v@/components/MultiUploader";
  import draggable from 'vuedraggable';
  import directionMixin from "@m@/common/direction.mixin";
  export default {
    name: "SimpleEditor",
    components: { MultiUploader, draggable },
    mixins: [directionMixin],
    props: {
      debug: {
        type: Boolean,
        default: ()=> Boolean(process.env.VUE_APP_DEBUG)
      },
      value: {
        type: Array,
        default: ()=> []
      },
      only: {
        type: Array,
        default: ()=> ['text', 'image', 'video', 'voice']
      },
      lang: {
        type: String,
        default: ()=> 'zh-UG'
      }
    },
    computed: {
      direction(){
        let { lang } = this;
        let { locale } = this.$i18n;
        if( lang === 'zh-CN' && locale === 'zh-UG' ){
          return 'direction-rtl';
        }else if( lang === 'zh-UG' && locale === 'zh-CN' ){
          return 'direction-rtl';
        }
      },
    },
    created() {
      this.content = Object.assign([], this.content, this.value);
      this.nextModelId = this.content.length ? this.content.length + 1 : 1
    },
    watch: {
      value: {
        handler(v){
          this.content = Object.assign([], this.content, v);
          this.nextModelId = this.content.length ? this.content.length + 1 : 1
        }
      }
    },
    data(){
      return {
        content: [],
        modelTypes: [
          {
            type: 'text',
            title: this.$t('COMPONENTS.SIMPLE_EDITOR.TYPES.text'),
            icon: 'fa fa-edit',
            variant: 'success'
          },
          {
            type: 'image',
            title: this.$t('COMPONENTS.SIMPLE_EDITOR.TYPES.image'),
            icon: 'fa fa-images',
            variant: 'success'
          },
          {
            type: 'video',
            title: this.$t('COMPONENTS.SIMPLE_EDITOR.TYPES.video'),
            icon: 'fa fa-video',
            variant: 'success'
          },
          {
            type: 'voice',
            title: this.$t('COMPONENTS.SIMPLE_EDITOR.TYPES.voice'),
            icon: 'fa fa-microphone-alt',
            variant: 'success'
          }
        ],
        nextModelId: 1
      }
    },
    methods: {
      onClickMaker(t){
        this.content.push(this.generateModel(t));
        this.$emit('input', this.content);
      },
      generateModel(modelType = 'text'){
        let model = {};
        switch(modelType){
          case "text":
            return Object.assign({id: null, type: 'text', content: null},{id: this.nextModelId++});
          case 'image':
            return Object.assign({id: null, type: 'image', src: []}, {id: this.nextModelId++});
          case 'voice':
            return Object.assign({id: null, type: 'voice', src: null},{id: this.nextModelId++});
          case 'video':
            return Object.assign({id: null, type: 'video', src: null},{id: this.nextModelId++});
        }
        return model;
      },
      deleteItem(index){
        const content = Object.assign([], JSON.parse(JSON.stringify(this.content)));
        content.splice(index, 1);
        this.content = content;
        this.$emit('input', content);
      }
    },
  }
</script>

<style scoped>

</style>
