<template>
  <b-form-group :id="id + '_form_group'" label-class="d-flex" :state="state">
    <template v-slot:label>
      <label v-if="label" :for="id + '_form'">{{ label }}</label>
      <span class="slider-top-value">{{ slider }} {{ suffix ? suffix : '' }}</span>
    </template>
    <b-form-input :id="id + '_form'" @input="handleValue" v-model="slider" type="range" :min="min" :max="max" :step="step" :state="state" />
    <div class="form-control-ticks d-flex justify-content-between">
      <div class="form-control-tick cursor-pointer" @click="onClickTick" v-for="(tick, index) in ticks" :key="index" :data-val="tick.val">{{ tick.title }}</div>
    </div>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ feedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
  import { numConvert } from "@/common/helpers/helpers";
  export default {
    name: "index",
    props: {
      id: {
        type: String,
        default: ()=> 'slider'
      },
      state: {
        type: Boolean,
        default: ()=> null
      },
      feedback: {
        type: String
      },
      label: {
        type: String
      },
      suffix: {
        type: String
      },
      min: {
        type: [Number, String],
        default: () => 0
      },
      max: {
        type: [Number, String],
        default: () => 10000
      },
      step: {
        type: [Number, String],
        default: () => 500
      },
      value: {
        type: [Number, String],
        default: ()=> 0
      },
      maxStep: {
        type: [Number, String],
        default: () => 5
      }
    },
    components: {},
    mixins: [],
    mounted() {
    },
    created() {
      this.slider = this.value;
    },
    watch: {
      value: {
        handler(v){
          this.slider = v;
        },
        immediate: true
      }
    },
    computed: {
      _ticks() {
        let { max } = this;
        return ['0', numConvert(max * 0.25), numConvert(max * 0.5), numConvert(max * 0.75), numConvert(max)];
      },
      ticks() {
        let { max, maxStep } = this;
        const step = maxStep - 1;
        const offset = 1 / step;
        let values = [{val: 0, title: '0'}];
        for (let i = 1; i <= step; i++) {
          values.push({
            title: numConvert(max * (offset * i)),
            val: max * (offset * i)
          });
        }
        return values;
      },
    },
    data() {
      return {
        loading: false,
        slider: 0
      }
    },
    methods: {
      handleValue(e){
        this.$emit('input', e);
      },
      onClickTick(e){
        const { dataset: {val} } = e.target;
        this.slider = parseInt(val);
        this.$emit('input', this.slider);
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-group {
    &.is-invalid {
      .col-form-label * {
        color: var(--red);
      }
      .form-control-ticks {
        color: var(--red);
      }
    }
    .col-form-label {
      .slider-top-value {
        margin-inline-start: auto;
      }
    }
    .form-control-ticks {
      height: 20px;
      position: relative;
      color: #bfbfbf;

      .form-control-tick {
        min-width: 0;
        height: 16px;
        text-align: center;

        &:first-child {
          padding-inline-end: 13px;
        }
      }
    }
  }

</style>
