<template>
  <div class="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
    <div class="kt-grid__item kt-wizard-v2__aside">
      <!--begin: Form Wizard Nav -->
      <div class="kt-wizard-v2__nav">
        <!--doc: Remove "kt-wizard-v2__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
        <div class="kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable">
          <div v-if="action === 'create'" class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step" data-ktwizard-state="current">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-id-card-alt"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.AUTH_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.AUTH_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step" data-ktwizard-state="current">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-font"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.TITLE_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.TITLE_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-edit"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONTENT_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONTENT_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-ad"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.BANNER_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.BANNER_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-sitemap"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.MANAGER_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.MANAGER_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-cogs"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONFIG_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONFIG_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-info-circle"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PREVIEW_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PREVIEW_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end: Form Wizard Nav -->
    </div>
    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">

      <!--begin: Form Wizard Form-->
      <div class="kt-form" id="kt_form">
        <div v-if="action ==='create'" class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.AUTH_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <mini-program-scan-app-code @success="onAuthSuccess" />
            </div>
          </div>
        </div>

        <!--begin: Form Wizard Step 1-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-tabs align="center" nav-class="nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success">
                <b-tab :title="$t('COMMON.TABLIST.TITLE.UG')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title')" label-for="ug-title">
                        <b-form-input id="ug-title" :class="[direction_rtl]" v-model="formData.title['zh-UG']" :state="validateState('zh-UG', 'title')" aria-describedby="ug-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title')" />
                        <b-form-invalid-feedback id="ug-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-main-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.main_title')" label-for="ug-main-title">
                        <b-form-input id="ug-main-title" :class="[direction_rtl]" v-model="formData.main_title['zh-UG']" :state="validateState('zh-UG', 'main_title')" aria-describedby="ug-main-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.main_title')" />
                        <b-form-invalid-feedback id="ug-main-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.main_title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-describe" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.describe')" label-for="ug-describe">
                        <b-form-input :class="[direction_rtl]" id="ug-describe" v-model="formData.describe['zh-UG']" type="text" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.describe')" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('COMMON.TABLIST.TITLE.CN')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title')" label-for="cn-title">
                        <b-form-input id="cn-title" :class="[direction_ltr]" v-model="formData.title['zh-CN']" :state="validateState('zh-CN', 'title')" aria-describedby="cn-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title')" />
                        <b-form-invalid-feedback id="cn-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-main-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.main_title')" label-for="cn-main-title">
                        <b-form-input id="cn-main-title" :class="[direction_ltr]" v-model="formData.main_title['zh-CN']" :state="validateState('zh-CN', 'main_title')" aria-describedby="cn-main-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.main_title')" />
                        <b-form-invalid-feedback id="cn-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.main_title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-describe" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.describe')" label-for="cn-describe">
                        <b-form-input :class="[direction_ltr]" id="cn-describe" v-model="formData.describe['zh-CN']" type="text" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.describe')" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col sm="12" md="4">
                  <b-form-group :state="validateState('cover_image')" aria-describedby="cover_image-live-feedback" id="input-group-avatar_image" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.cover_image')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.cover_image')" label-for="cover_image">
                    <image-uploader v-model="formData.cover_image" check-size :width="520" :height="416" :upload-form="{strategy: 'article'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                    <b-form-invalid-feedback id="cover_image-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.cover_image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 1-->

        <!--begin: Form Wizard Step 2-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content">
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-tabs align="center" nav-class="nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success">
                <b-tab :title="$t('COMMON.TABLIST.TITLE.UG')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group :state="validateState('zh-UG', 'content')" aria-describedby="ug-content-live-feedback" id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.LABEL.content')" label-for="ug-content">
                        <simple-editor v-model="formData.content['zh-UG']" :only="['text', 'image']" />
                        <b-form-invalid-feedback id="ug-content-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.FEEDBACK.content') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('COMMON.TABLIST.TITLE.CN')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group :state="validateState('zh-CN', 'content')" aria-describedby="cn-content-live-feedback" id="input-group-cn-content" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.LABEL.content')" label-for="cn-content">
                        <simple-editor v-model="formData.content['zh-CN']" lang="zh-CN" :only="['text', 'image']" />
                        <b-form-invalid-feedback id="cn-content-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.FEEDBACK.content') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 2-->

        <!--begin: Form Wizard Step 3-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-gender" :state="validateState('banner_type', 'banner')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.banner_type')" label-for="banner_type" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.banner_type')">
                    <b-form-radio-group
                      id="gender"
                      v-model="formData.banner.banner_type"
                      :options="bannerOptions"
                      @change="onChangeBannerType"
                      buttons />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="12" v-if="formData.banner.banner_type === 'image'">
                  <b-form-group id="input-group-banners" :state="validateState('banners', 'banner')" aria-describedby="banners-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.image')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.image')" label-for="banners">
                    <multi-uploader v-model="formData.banner.banners" :data="{strategy: 'salon'}" :limit="4" />
                    <b-form-invalid-feedback id="banners-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.FEEDBACK.image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="12" v-if="formData.banner.banner_type === 'video'">
                  <b-form-group id="input-group-banners" :state="validateState('banners', 'banner')" aria-describedby="banners-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.video')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.video')" label-for="banners">
                    <multi-uploader video v-model="formData.banner.banners" :data="{strategy: 'video'}" :limit="1" list-type="text" accept="video/*, video/x-msvideo, video/mpeg, video/3gpp, video/mp4" />
                    <b-form-invalid-feedback id="banners-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.FEEDBACK.video') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 3-->

        <!--begin: Form Wizard Step 4-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-regulator_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.regulator_id')" label-for="regulator_id">
                    <b-input-group>
                      <b-form-input :state="validateState('regulator_id', 'manager')" aria-describedby="regulator_id-live-feedback" readonly :value="regulatorText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.regulator_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="regulator_id" @click="showOrganization('regulator')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="regulator_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.regulator_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-actuator_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.actuator_id')" label-for="actuator_id">
                    <b-input-group>
                      <b-form-input :state="validateState('actuator_id', 'manager')" aria-describedby="actuator_id-live-feedback" readonly :value="actuatorText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.actuator_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="actuator_id" @click="showOrganization('actuator')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="actuator_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.actuator_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-donation_manager_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.donation_manager_id')" label-for="donation_manager_id">
                    <b-input-group>
                      <b-form-input :state="validateState('donation_manager_id', 'manager')" aria-describedby="donation_manager_id-live-feedback" readonly :value="donationText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.donation_manager_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="donation_manager_id" @click="showOrganization('donation')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="donation_manager_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.donation_manager_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-project_leader_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.project_leader_id')" label-for="project_leader_id">
                    <b-input-group>
                      <b-form-input :state="validateState('project_leader_id', 'manager')" aria-describedby="project_leader_id-live-feedback" readonly :value="projectLeaderText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.project_leader_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="project_leader_id" @click="showOrganization('leader')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="project_leader_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.project_leader_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-merchant_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.merchant_id')" label-for="merchant_id">
                    <b-input-group>
                      <b-form-input :state="validateState('merchant_id', 'manager')" aria-describedby="merchant_id-live-feedback" readonly :value="merchantText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.merchant_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="merchant_id" @click="showOrganization('merchant')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="merchant_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.merchant_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-record_number" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number')" label-for="record_number">
                    <b-form-input id="record_number" :class="[direction_ltr]" v-model="formData.manager.record_number" :state="validateState('record_number', 'manager')" aria-describedby="record_number-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number')" />
                    <b-form-invalid-feedback id="record_number-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <app-slider :max="10000000" max-step="11" :step="10000" id="amount_raised" v-model="formData.raise"  :suffix="$t('COMMON.$')" :state="validateState('raise')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.amount_raised')" :feedback="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.amount_raised')" />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 4-->

        <!--begin: Form Wizard Step 5-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content">
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <divider :title="$t('COMMON.TABLIST.TITLE.UG')" />
              <b-row>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-part" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.part')" label-for="config-part">
                    <b-form-input id="config-part" type="number" :class="[direction_rtl]" v-model="formData.config.part" :state="validateState('part', 'config')" aria-describedby="config-part-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.part')" />
                    <b-form-invalid-feedback id="config-part-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.FEEDBACK.part') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-ug-operator" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.operator')" label-for="ug-operator">
                    <b-form-input id="ug-operator" :class="[direction_rtl]" v-model="formData.config.operator['zh-UG']" :state="validateState('zh-UG', 'config', 'operator')" aria-describedby="ug-operator-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.operator')" />
                    <b-form-invalid-feedback id="ug-operator-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.FEEDBACK.operator') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-ug-gift" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.gift')" label-for="ug-gift">
                    <b-form-input id="ug-gift" :class="[direction_rtl]" v-model="formData.config.gift['zh-UG']" :state="validateState('zh-UG', 'config', 'gift')" aria-describedby="ug-gift-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.gift')" />
                    <b-form-invalid-feedback id="ug-gift-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.FEEDBACK.gift') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <divider :title="$t('COMMON.TABLIST.TITLE.CN')" />
              <b-row>
                <b-col sm="6" md="4"></b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-cn-operator" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.operator')" label-for="cn-operator">
                    <b-form-input id="cn-operator" :class="[direction_ltr]" v-model="formData.config.operator['zh-CN']" :state="validateState('zh-CN', 'config', 'operator')" aria-describedby="cn-operator-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.operator')" />
                    <b-form-invalid-feedback id="cn-operator-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.FEEDBACK.operator') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-cn-gift" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.gift')" label-for="cn-gift">
                    <b-form-input id="cn-gift" :class="[direction_rtl]" v-model="formData.config.gift['zh-CN']" :state="validateState('zh-CN', 'config', 'gift')" aria-describedby="cn-gift-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.gift')" />
                    <b-form-invalid-feedback id="cn-gift-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.FEEDBACK.gift') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <divider :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.DIVIDER.options')" />
              <b-row v-for="(option, index) in formData.config.options" :key="index">
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-total_fee" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.options.total_fee')" label-for="options-total_fee">
                    <b-form-input id="options-total_fee" type="number" :class="[direction_rtl]" v-model="option.total_fee" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.options.total_fee')" />
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-label_text_ug" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.options.label_text_ug')" label-for="options-label_text_ug">
                    <b-form-input id="options-label_text_ug" v-model="option.label_text_ug" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.options.label_text')" />
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-label_text_cn" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.options.label_text_cn')" label-for="options-label_text_cn">
                    <b-form-input id="options-label_text_cn" :class="[direction_ltr]" v-model="option.label_text_cn" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.PLACEHOLDER.options.label_text')" />
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="1" class="d-inline-flex justify-content-center align-items-center ">
                  <b-button variant="danger" size="sm" @click="formData.config.options.splice(index, 1)"><i class="fa fa-trash mx-0"></i></b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="4">
                  <b-button variant="primary" v-if="isShowAddButton" @click="onClickAddOptionsItem"><i class="fa fa-plus"></i>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONFIG_FORM.LABEL.options.addButton') }}</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 5-->

        <!--begin: Form Wizard Step 6-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content">
          <div class="kt-heading kt-heading--md">{{ formData.title[$i18n.locale].title }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__review">
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.BANNER.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  <template v-if="formData.banner.banner_type === 'image'">
                    <b-avatar-group size="60" rounded overlap="-0.6">
                      <b-avatar v-for="banner in formData.banner.banners" :src="banner.url" :key="banner.path" text="!"></b-avatar>
                    </b-avatar-group>
                  </template>
                  <template v-else-if="formData.banner.banner_type === 'video'">
                    <video class="video-player" controls v-if="formData.banner.banners.length" :src="generateVideoUrl(formData.banner.banners[0].path)"></video>
                  </template>
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.regulator_id") }}: {{ regulatorText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.actuator_id") }}: {{ actuatorText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.project_leader_id") }}: {{ projectLeaderText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.donation_manager_id") }}: {{ donationText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.merchant_id") }}: {{ merchantText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.record_number") }}: {{ formData.manager.record_number }}<br />
                  {{ mainTitleText }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 6-->

        <!--begin: Form Actions -->
        <div class="kt-form__actions">
          <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
            {{ $t("COMMON.FORM.BUTTONS.PREV") }}
          </button>
          <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
            {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
          </button>
          <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
            {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
          </button>
        </div>

        <!--end: Form Actions -->
      </div>

      <!--end: Form Wizard Form-->
    </div>
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.regulator_id')" id="choose-regulator" :options="regulatorOptions" v-model="regulatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" @change="onChangeRegulator" image-field="logo_image_preview" :form-value="regulator_id" name="regulator_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.donation_manager_id')" id="choose-donation-manager" :options="regulatorOptions" v-model="donationManagerModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" image-field="logo_image_preview" @change="onChangeDonationManager" :form-value="donation_manager_id" name="donation_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.actuator_id')" id="choose-actuator" :options="actuatorOptions" v-model="actuatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" image-field="logo_image_preview" @change="onChangeActuatorManager" :form-value="actuator_id" name="actuator_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.project_leader_id')" id="choose-project-leader" :options="projectLeadersOptions" v-model="projectLeaderModalState" :title-field="`${$i18n.locale}.full_name`" :describe-field="`${$i18n.locale}.describe`" image-field="avatar_image_preview" @change="onChangeProjectLeaderManager" :form-value="project_leader_id" name="project_leader_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.merchant_id')" id="choose-merchant_id" :options="merchantOptions" v-model="merchantModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" sub-title-field="mch_id" @change="onChangeMerchantManager" :form-value="merchant_id" name="merchant_option" />
  </div>
</template>

<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import MultiUploader from "@v@/components/MultiUploader";
  import SimpleEditor from "@v@/components/SimpleEditor";
  import AppSlider from "@v@/components/AppSlider";
  import ChooseOrganizationModal from "@v@/components/modals/choose.organization.modal";
  import MiniProgramScanAppCode from "@v@/components/user/MiniProgramScanAppCode";
  import Divider from "@v@/partials/widgets/Divider";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import articleMixin from "@m@/article.mixin";
  import directionMixin from "@m@/common/direction.mixin";
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  import {mapGetters} from "vuex";
  import ApiService from "@/common/api.service";
  export default {
    name: "index",
    components: {ImageUploader, SimpleEditor, MultiUploader, AppSlider, ChooseOrganizationModal, MiniProgramScanAppCode, Divider},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
      rid: {
        type: [String, Number],
      },
    },
    mixins: [articleMixin, validateStateMixin, validationMixin, sweetAlertMixin, directionMixin],
    created() {
      this.getArticleCoreData();
      if (this.action === 'edit'){
        this.getArticleSubject(this.id, this.rid)
          .then((res)=>{
            let { version: onlineVersion, subject: { version } } = res.data;
            if( onlineVersion > version ){
              return this.$router.replace({ name: "article.versions", params: { id: this.id } });
            }
            this.formData = Object.assign({}, this.formData, this.articleSubject);
            if ( ! this.formData.config ){
              this.formData.config = {
                part: 10,
                operator: {
                  'zh-UG': "كىشىلىك",
                  'zh-CN': '人次'
                },
                gift: {
                  'zh-UG': "ياردەم پۇلى",
                  'zh-CN': "补助"
                },
                options: [
                  {
                    total_fee: 10,
                    label_text_ug: 'بىر كىشىلىك ياردەم پۇلى',
                    label_text_cn: '一人次补助',
                  }
                ],
              };
            }
            if( ! this.formData.config.options ){
              this.$set(this.formData.config, 'options', [
                {
                  total_fee: 10,
                  label_text_ug: 'بىر كىشىلىك ياردەم پۇلى',
                  label_text_cn: '一人次补助',
                }
              ])
            }

            this.setArticleLockState(this.rid, 1, 'project');
          })
          .catch(err=>{
            console.info('getArticleSubject.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    destroyed() {
      if (this.action === 'edit' ){
        this.setArticleLockState(this.rid, 0, 'project');
      }
    },
    computed: {
      ...mapGetters(['articleCoreData', 'articleSubject']),
      lang(){
        const { locale } = this.$i18n
        return locale
      },
      regulator_id(){
        const { manager: {regulator_id} } = this.formData;
        return regulator_id
      },
      donation_manager_id(){
        const { manager: {donation_manager_id} } = this.formData;
        return donation_manager_id
      },
      actuator_id(){
        const { manager: {actuator_id} } = this.formData;
        return actuator_id
      },
      project_leader_id(){
        const { manager: {project_leader_id} } = this.formData;
        return project_leader_id
      },
      merchant_id(){
        const { manager: {merchant_id} } = this.formData;
        return merchant_id
      },
      regulatorOptions(){
        let { regulators = [] } = this.articleCoreData || {};
        return regulators;
      },
      actuatorOptions(){
        let { actuators = [] } = this.articleCoreData || {};
        return actuators;
      },
      projectLeadersOptions(){
        let { project_leaders = [] } = this.articleCoreData || {};
        return project_leaders;
      },
      merchantOptions(){
        let { merchants = [] } = this.articleCoreData || {};
        return merchants;
      },
      regulatorText(){
        let { regulator_id } = this.formData.manager;
        if( regulator_id ){
          let { regulatorOptions } = this;
          return this.getProperty(regulatorOptions, regulator_id);
        }
        return '';
      },
      donationText(){
        let { donation_manager_id } = this.formData.manager;
        if( donation_manager_id ){
          let { regulatorOptions } = this;
          return this.getProperty(regulatorOptions, donation_manager_id);
        }
        return '';
      },
      actuatorText(){
        let { actuator_id } = this.formData.manager;
        if( actuator_id ){
          let { actuatorOptions } = this;
          return this.getProperty(actuatorOptions, actuator_id);
        }
        return '';
      },
      projectLeaderText(){
        let { project_leader_id } = this.formData.manager;
        if( project_leader_id ){
          let { projectLeadersOptions } = this;
          return this.getProperty(projectLeadersOptions, project_leader_id, 'full_name');
        }
        return '';
      },
      merchantText(){
        let { merchant_id } = this.formData.manager;
        if( merchant_id ){
          let { merchantOptions } = this;
          return this.getProperty(merchantOptions, merchant_id);
        }
      },
      mainTitle(){
        const { lang } = this;
        const { main_title } = this.formData;
        return main_title[lang];
      },
      config(){
        const { config } = this.formData;
        return config;
      },
      operatorText(){
        const { lang } = this;
        const { operator } = this.config;
        return operator[lang]
      },
      giftText(){
        const { lang } = this;
        const { gift } = this.config;
        return gift[lang]
      },
      mainTitleText(){
        const { lang, mainTitle, operatorText, giftText } = this;
        switch (lang) {
          case 'zh-UG':
            let suffix = 'غا';
            return `${mainTitle}${suffix} 1 ${operatorText} ${giftText}`;
            break;
            case 'zh-CN':
              let prefix = '为';
              return `${prefix}${mainTitle} 1 ${operatorText} ${giftText}`;
              break;
        }
      },
      isShowAddButton(){
        const { config: { options = [] } } = this.formData
        if( options && options.length > 5 ){
          return false;
        }else if( options ){
          return true;
        }
      },
    },
    watch: {
      modelData: {
        handler(v){
          if( v && v.hasOwnProperty('id') ){
            this.formData = {...this.formData, ...this.modelData}
          }
        },
        immediate: true
      }
    },
    validations(){
      let validates = {
        cover_image: { required },
        raise: { required },
      };
      if( this.action === 'create' ){
        validates.user_id = { required }
      }
      validates.title = {
        "zh-UG": { required },
        "zh-CN": { required }
      };
      validates.main_title = {
        "zh-UG": { required },
        "zh-CN": { required }
      };
      validates.content = {
        "zh-UG": { required },
        "zh-CN": { required }
      };

      /*validates.config.part = { required };
      validates.config.operator = {
        "zh-UG": { required },
        "zh-CN": { required }
      };
      validates.config.gift = {
        "zh-UG": { required },
        "zh-CN": { required }
      };*/

      validates.config = {
        part: { required },
        operator: {
          "zh-UG": { required },
          "zh-CN": { required }
        },
        gift: {
          "zh-UG": { required },
          "zh-CN": { required }
        }
      };

      validates.banner = {
        banners: { required },
        banner_type: { required }
      };
      validates.manager = {
        regulator_id: { required },
        actuator_id: { required },
        project_leader_id: { required },
        donation_manager_id: { required },
        record_number: { required },
        merchant_id: { required },
      };

      return {
        formData: validates
      }
    },
    data() {
      return {
        wizard: null,
        loading: false,
        regulatorModalState: false,
        actuatorModalState: false,
        projectLeaderModalState: false,
        donationManagerModalState: false,
        merchantModalState: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        bannerOptions: [
          { text: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.image_type'), value: 'image' },
          { text: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.video_type'), value: 'video' },
        ],
        formData: {
          type: 'project',
          cover_image: null,
          title: {
            'zh-UG': '',
            'zh-CN': '',
          },
          main_title: {
            'zh-UG': '',
            'zh-CN': '',
          },
          content: {
            'zh-UG': [],
            'zh-CN': [],
          },
          describe: {
            'zh-UG': '',
            'zh-CN': '',
          },
          config: {
            part: "10",
            operator: {
              'zh-UG': 'كىشىلىك',
              'zh-CN': '人次',
            },
            gift: {
              'zh-UG': 'ياردەم پۇلى',
              'zh-CN': '补助',
            },
            options: [
              {
                total_fee: 10,
                label_text_ug: 'بىر كىشىلىك ياردەم پۇلى',
                label_text_cn: '一人次补助',
              }
            ],
          },
          banner: {
            banners: [], // banner 数据集
            banner_type: '' // banner类型
          },
          manager: {
            regulator_id: null,
            actuator_id: null,
            project_leader_id: null,
            donation_manager_id: null,
            merchant_id: null,
            record_number: null,
          },
          sort: null,
          user_id: 1
        },
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeArticle(this.formData)
            .then(()=> {
              this.$router.replace({ name: `article.home` });
            }).catch(err=>{
              console.info('storeArticle.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          delete this.formData.lang;
          this.updateArticle(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateArticle.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v2', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', wizardObj){
        this.$v.formData[formName].$touch();
        if (this.$v.formData[formName].$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          // wizardObj.goNext();
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        const { action } = this;
        switch (currentStep) {
          case (action === 'create' ? 1 : 0):
            this.validHandler('user_id', wizardObj);
            break;
          case (action === 'create' ? 2 : 1):
            this.validHandler('title', wizardObj);
            this.validHandler('main_title', wizardObj);
            this.validHandler('cover_image', wizardObj);
            break;
          case (action === 'create' ? 3 : 2):
            this.validHandler('content', wizardObj);
            break;
          case (action === 'create' ? 4 : 3):
            this.validHandler('banner', wizardObj);
            break;
          case (action === 'create' ? 5 : 4):
            this.validHandler('manager', wizardObj);
            break;
          case (action === 'create' ? 6 : 5):
            this.validHandler('config', wizardObj);
            break;
        }
      },
      onChangeBannerType(){
        this.formData.banner.banners = [];
      },
      showOrganization(type = 'regulator'){
        switch (type) {
          case "regulator":
            this.regulatorModalState = true;
            break;
          case "actuator":
            this.actuatorModalState = true;
            break;
          case "leader":
            this.projectLeaderModalState = true;
            break;
          case "donation":
            this.donationManagerModalState = true;
            break;
          case "merchant":
            this.merchantModalState = true;
            break;
        }
      },
      hideOrganization(type = 'regulator'){
        switch (type) {
          case "regulator":
            this.regulatorModalState = false;
            break;
          case "actuator":
            this.actuatorModalState = false;
            break;
          case "leader":
            this.projectLeaderModalState = false;
            break;
          case "donation":
            this.donationManagerModalState = false;
            break;
          case "merchant":
            this.merchantModalState = false;
            break;
        }
      },
      onChangeMerchantManager(e){
        this.formData.manager.merchant_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('merchant');
        })
      },
      onChangeRegulator(e){
        this.formData.manager.regulator_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('regulator');
        })
      },
      onChangeDonationManager(e){
        this.formData.manager.donation_manager_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('donation');
        })
      },
      onChangeActuatorManager(e){
        this.formData.manager.actuator_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('actuator');
        })
      },
      onChangeProjectLeaderManager(e){
        this.formData.manager.project_leader_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('leader');
        })
      },
      getProperty(obj, id, column = 'title'){
        let { locale } = this.$i18n;
        let result = Object.assign({}, ...obj.filter(item=> item.id == id));
        return result.hasOwnProperty(locale) ? result[locale][column] : '' ;
      },
      onAuthSuccess(user){
        this.formData.user_id = user.id;
      },
      generateVideoUrl(path = ''){
        return ApiService.generateUrl('manager/fetch', `file=${path}`)
      },
      // 点击添加新的选项
      onClickAddOptionsItem(){
        this.formData.config.options.push({
          total_fee: 0,
          label_text_ug: '',
          label_text_cn: '',
        })
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-2";
  .b-avatar-group-inner {
    gap: 10px;
  }

  .video-player {
    height: 150px;
    width: 300px;
  }
</style>
