export default {
  computed: {
    direction(){
      let { lang } = this;
      let { locale } = this.$i18n;
      if( lang === 'zh-CN' && locale === 'zh-UG' ){
        return 'direction-rtl';
      }else if( lang === 'zh-UG' && locale === 'zh-CN' ){
        return 'direction-rtl';
      }
      return '';
    },
    direction_rtl(){
      let { locale } = this.$i18n;
      if( locale === 'zh-UG' ){
        return 'direction-rtl';
      }else if( locale === 'zh-CN' ){
        return 'direction-rtl';
      }
      return '';
    },
    direction_ltr(){
      let { locale } = this.$i18n;
      if( locale === 'zh-UG' ){
        return 'direction-ltr';
      }else if( locale === 'zh-CN' ){
        return 'direction-ltr';
      }
      return '';
    }
  }
}
