import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  ARTICLE_ALLOCATION_ACTION,
  GET_ARTICLE,
  GET_ARTICLE_BASIC_QUANTITY,
  GET_ARTICLE_CORE_DATA,
  GET_ARTICLE_RAISE,
  GET_ARTICLE_STATISTICS,
  GET_ARTICLE_STATISTICS_USERS, GET_ARTICLE_SUBJECT,
  GET_ARTICLE_VERSION,
  GET_ARTICLES, MAKE_COPY_VERSION,
  SET_ARTICLE_LOCK_STATE,
  SET_ARTICLE_ONLINE, SET_ARTICLE_STATUS,
  STORE_ARTICLE,
  UPDATE_ARTICLE
} from "@/store/modules/article.module";
export  default {
  methods: {
    storeArticle(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_ARTICLE, formData), actions)
    },
    getArticle(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE, id), actions)
    },
    getArticleSubject(id, rid, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_SUBJECT, { id, rid }), actions)
    },
    updateArticle(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_ARTICLE, { id, formData }), actions)
    },
    getArticles(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLES, page), actions)
    },
    getArticleVersion(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_VERSION, id), actions)
    },
    getArticleCoreData(actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_CORE_DATA), actions)
    },
    setArticleLockState(id, state = 0, type = 'raise', actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(this.$store.dispatch(SET_ARTICLE_LOCK_STATE, {id, state, type}), actions)
    },
    setArticleOnline(id, version = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(this.$store.dispatch(SET_ARTICLE_ONLINE, {id, version}), actions)
    },
    setArticleStatus(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(this.$store.dispatch(SET_ARTICLE_STATUS, {id, formData}), actions)
    },

    getArticleStatistics(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_STATISTICS, id), actions)
    },
    getArticleBasicQuantity(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_BASIC_QUANTITY, id), actions)
    },
    getArticleStatisticsUsers(id, page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_STATISTICS_USERS, { id, page }), actions)
    },
    setArticleAllocation(article_id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(this.$store.dispatch(ARTICLE_ALLOCATION_ACTION, {id: article_id, formData}), actions)
    },
    makeCopyVersion(rid, aid, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'updating'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'updated'}); },
    }){
      return mixinPromise(this.$store.dispatch(MAKE_COPY_VERSION, { rid, aid }), actions)
    },
  }
}
