<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center" v-if="false">
        <b-img-lazy :src="appCodeUrl" rounded="" width="200px" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <!--begin::Widget -->
        <div class="kt-widget kt-widget--user-profile-4">
          <div class="kt-widget__head">
            <div class="kt-widget__media">
              <b-overlay :show="loading" rounded="circle" variant="success">
                <span v-if="[1, 2, -1, -2].includes(app_code_err)" class="kt-widget__mask rounded d-flex flex-column align-items-center justify-content-center">
                  <i class="fa fa-times kt-font-danger" v-if="[-1, -2].includes(app_code_err)"></i>
                  <i class="fa fa-check kt-font-success" v-else-if="app_code_err === 1"></i>
                </span>
                <b-img v-if="! user" @load="onImageLoad" width="130" height="130" class="kt-widget__img" :src="appCodeUrl" rounded="circle" blank-color="#fff" />
                <b-img v-else-if="user" width="130" height="130" class="kt-widget__img" :src="user.avatar" rounded="circle" blank-color="#fff" />
              </b-overlay>
            </div>
            <div class="kt-widget__content">
              <div class="kt-widget__section">
                <a class="kt-widget__username cursor-default">
                  <template v-if="app_code_err === 0">
                    {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.LABEL.tips.init') }}
                  </template>
                  <template v-else-if="app_code_err === 1">
                    {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.LABEL.tips.scanned') }}
                  </template>
                  <template v-else-if="app_code_err === 2">
                    {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.LABEL.tips.completed') }}
                  </template>
                  <template v-else-if="app_code_err === -1">
                    {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.LABEL.tips.refresh') }}
                  </template>
                  <template v-else-if="app_code_err === -2">
                    {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.LABEL.tips.unidentified') }}
                  </template>
                  <template v-else-if="user">
                    {{ user.nickname }}
                  </template>
                </a>
                <div class="kt-widget__button">
                    <b-button v-if="!user" :disabled="loading" @click="onClickRefresh" size="sm" variant="success"><b-spinner v-if="loading" small /> {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.BUTTON.refresh') }}</b-button>
                    <b-button v-else-if="user" :disabled="loading" @click="onClickRefresh" size="sm" variant="success"><b-spinner v-if="loading" small /> {{ $t('COMPONENTS.MINIPROGRAM_SCAN_APP_CODE.BUTTON.redo') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Widget -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import {mapGetters} from "vuex";
  import authMixin from "@m@/miniprogram.auth.mixin";
  import ApiService from "@c@/api.service";
  import { getToken } from "@c@/jwt.service";
  let eventSource = null;

  export default {
    name: "MiniProgramScanAppCode",
    props: {},
    computed: {
      ...mapGetters(['miniprogram_auth_error']),
      appCodeUrl(){
        if( this.scene ){
          return ApiService.generateUrl('manager/authorizations/app-code-for-login', `scene=${this.scene}`)
        }
        return null;
      },
    },
    data() {
      return {
        loading: true,
        scene: null,
        app_code_err: 0,
        user: null,
      }
    },
    mixins: [authMixin],
    created() {
      this.scene = this.generateSceneValue();
    },
    mounted() {},
    beforeDestroy(){
      eventSource.close();
    },
    methods: {
      generateSceneValue(length = 11) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let sceneValue = '';
        for (let i = 0; i < length; i++) {
          sceneValue += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return sceneValue;
      },
      onClickRefresh(){
        this.loading = true;
        this.scene = null;
        this.user = null;
        this.scene = this.generateSceneValue();
        if( eventSource ){
          eventSource.close();
        }
      },
      onImageLoad(e){
        this.loading = false;
        this.app_code_err = 0;
        this.$nextTick(()=>{
          this.handleSSE();
        });
      },
      handleSSE(){
        let url = ApiService.generateUrl('manager/authorizations/scan-app-code-stream', `scene=${this.scene}&token=${getToken()}`);
        eventSource = new EventSource(url);
        const self = this;
        eventSource.addEventListener("login", function(event) {
          const data = JSON.parse(event.data);
          self.app_code_err = data.code;
          if( [-1, -2].includes(data.code) ){
            eventSource.close();
          }
          if( [2].includes(data.code) ){
            eventSource.close();
            self.user = data.user || null;
            self.app_code_err = null;
            self.scene = null;
            self.$emit('success', self.user);
          }
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .kt-widget__username {
    font-size: 1rem !important;
  }

  .kt-widget__img {
    max-width: 130px !important;
    max-height: 130px !important;
  }
</style>
