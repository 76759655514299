<template>
  <label class="kt-option" :class="[plan ? 'kt-option--plain' : '']">
    <span class="kt-option__control">
      <span class="kt-radio" :class="[variant ? 'kt-radio--' + variant : '', disabled ? 'kt-radio--disabled' : '']">
        <input type="radio" :disabled="disabled" :name="name" :value="val" :checked="val == formValue" @change="onChangeOption">
        <span></span>
      </span>
    </span>
    <span class="kt-option__label">
      <span class="kt-option__head">
        <span class="kt-option__title">{{ title }}</span>
        <span class="kt-option__focus" v-if="! plan && subTitle && ! imageField">{{ subTitle }}</span>
        <span class="kt-option__focus" v-if="! plan && imageField"><b-avatar :src="image" rounded /></span>
      </span>
      <span class="kt-option__body" v-if="describe">{{ describe }}</span>
    </span>
  </label>
</template>

<script>
  export default {
    name: "index",
    components: {},
    props: {
      formValue: {},
      option: {
        type: Object,
        default: ()=>{
          return {};
        }
      },
      name: {
        type: String,
        default: ()=> 'm_option'
      },
      variant: {
        type: String,
        validator: (value)=>{
          return ['success', 'warning', 'danger', 'info', 'primary', 'light'].includes(value)
        }
      },
      disabled: {
        type: Boolean
      },
      plan: {
        type: Boolean
      },
      titleField: {
        type: String,
        default: ()=> 'title',
      },
      subTitleField: {
        type: String,
        default: ()=> 'sub-title',
      },
      valueField: {
        type: String,
        default: ()=> 'value',
      },
      describeField: {
        type: String,
        default: ()=> 'describe',
      },
      imageField: {
        type: String
      },
    },
    computed: {
      title(){
        let { titleField } = this;
        if( ! titleField ) return '';
        let option = this.option;
        return this.getProperty(option, titleField);
      },
      subTitle(){
        let { subTitleField } = this;
        if( ! subTitleField ) return '';
        let option = this.option;
        return this.getProperty(option, subTitleField);
      },
      val(){
        let { valueField } = this;
        if( ! valueField ) return '';
        let option = this.option;
        return this.getProperty(option, valueField);
      },
      describe(){
        let { describeField } = this;
        if( ! describeField ) return '';
        let option = this.option;
        return this.getProperty(option, describeField);
      },
      image(){
        let { imageField } = this;
        if( ! imageField ) return null;
        let option = this.option;
        return this.getProperty(option, imageField);
      },
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      getProperty(obj, field){
        let fieldStr = field ? field.replace(/\[(\w+)\]/g, ".$1") : '';
        let steps = fieldStr.split('.').filter(item=> item);
        for (const step in steps) {
          obj = obj[steps[step]] || ''
        }
        return obj;
      },
      onChangeOption(e){
        // console.info('onChangeOption', e);
        const { value } = e.target;
        this.$emit('input', value)
        this.$emit('change', value)
      },
    }
  }
</script>

<style scoped>
  .kt-option {
    flex-grow: 1;
  }
</style>
