<template>
  <base-modal :id="id" :title="title || localTitle" :size="size" v-model="modalState" >
    <b-row class="py-5">
      <b-col sm="12" md="12" class="d-flex justify-content-center gap-10">
        <b-button variant="success" :to="{name: 'article.store', params: {form: 'raise'}}">{{ $t("COMPONENTS.MODALS.SUBJECT_CREATE_TYPE.BUTTONS.RAISE") }}</b-button>
        <b-button variant="warning" :to="{name: 'article.store', params: {form: 'project'}}">{{ $t("COMPONENTS.MODALS.SUBJECT_CREATE_TYPE.BUTTONS.PROJECT") }}</b-button>
        <b-button variant="primary" :to="{name: 'article.store', params: {form: 'external'}}">{{ $t("COMPONENTS.MODALS.SUBJECT_CREATE_TYPE.BUTTONS.EXTERNAL") }}</b-button>
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import AppOption from "../AppOption";
  export default {
    name: "SubjectCreateTypeModal",
    components: { baseModal },
    mixins: [],
    props: {
      id: {
        type: String,
        default: () => 'subject-create-type-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      localTitle(){
        return this.$t("COMPONENTS.MODALS.SUBJECT_CREATE_TYPE.TITLE");
      }
    },
    data(){
      return {
      };
    },
    methods: {
      onChangeValue(e){
        this.$emit('change', e);
      },
    }
  }
</script>

<style scoped>

</style>
