<template>
  <div class="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
    <div class="kt-grid__item kt-wizard-v2__aside">
      <!--begin: Form Wizard Nav -->
      <div class="kt-wizard-v2__nav">
        <!--doc: Remove "kt-wizard-v2__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
        <div class="kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable">
          <div v-if="action === 'create'" class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step" data-ktwizard-state="current">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-id-card-alt"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.AUTH_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.AUTH_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step" data-ktwizard-state="current">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-font"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.TITLE_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.TITLE_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-edit"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONTENT_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.CONTENT_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-id-card"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PATIENT_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PATIENT_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-hospital"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.HOSPITAL_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.HOSPITAL_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-ad"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.BANNER_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.BANNER_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-images"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.EVIDENCES_IMAGE_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.EVIDENCES_IMAGE_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-id-badge"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PERSONAL_IMAGE_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PERSONAL_IMAGE_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-home"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PUBLIC_INFORMATION_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PUBLIC_INFORMATION_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-sitemap"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.MANAGER_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.MANAGER_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
          <div class="kt-wizard-v2__nav-item" :class="[$i18n.locale === 'zh-UG' ? 'ug' : '']" data-ktwizard-type="step">
            <div class="kt-wizard-v2__nav-body">
              <div class="kt-wizard-v2__nav-icon">
                <i class="fa fa-info-circle"></i>
              </div>
              <div class="kt-wizard-v2__nav-label">
                <div class="kt-wizard-v2__nav-label-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PREVIEW_ITEM.TITLE") }}
                </div>
                <div class="kt-wizard-v2__nav-label-desc">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.NAV_ITEMS.PREVIEW_ITEM.DESC") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end: Form Wizard Nav -->
    </div>
    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">

      <!--begin: Form Wizard Form-->
      <div class="kt-form" id="kt_form">
        <div v-if="action ==='create'" class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.AUTH_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <mini-program-scan-app-code @success="onAuthSuccess" />
            </div>
          </div>
        </div>

        <!--begin: Form Wizard Step 1-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-tabs align="center" nav-class="nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success">
                <b-tab :title="$t('COMMON.TABLIST.TITLE.UG')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title')" label-for="ug-title">
                        <b-form-input id="ug-title" :class="[direction_rtl]" v-model="formData.title['zh-UG'].title" :state="validateState('title', 'title', 'zh-UG')" aria-describedby="ug-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title')" />
                        <b-form-invalid-feedback id="ug-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-title-prefix" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title_prefix')" label-for="ug-title-prefix">
                        <b-form-input id="ug-title-prefix" :class="[direction_rtl]" v-model="formData.title['zh-UG'].title_prefix" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title_prefix')" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-ug-describe" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.describe')" label-for="ug-describe">
                        <b-form-input :class="[direction_rtl]" id="ug-describe" v-model="formData.title['zh-UG'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.describe')" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('COMMON.TABLIST.TITLE.CN')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title')" label-for="cn-title">
                        <b-form-input id="cn-title" :class="[direction_ltr]" v-model="formData.title['zh-CN'].title" :state="validateState('title', 'title', 'zh-CN')" aria-describedby="cn-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title')" />
                        <b-form-invalid-feedback id="cn-title-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-title-prefix" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.title_prefix')" label-for="cn-title-prefix">
                        <b-form-input id="cn-title-prefix" :class="[direction_ltr]" v-model="formData.title['zh-CN'].title_prefix" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.title_prefix')" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group id="input-group-cn-describe" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.describe')" label-for="cn-describe">
                        <b-form-input :class="[direction_ltr]" id="cn-describe" v-model="formData.title['zh-CN'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.describe')" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col sm="12" md="4">
                  <b-form-group :state="validateState('cover_image')" aria-describedby="cover_image-live-feedback" id="input-group-avatar_image" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.PLACEHOLDER.cover_image')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.LABEL.cover_image')" label-for="cover_image">
                    <image-uploader v-model="formData.cover_image" check-size :width="520" :height="416" :upload-form="{strategy: 'article'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                    <b-form-invalid-feedback id="cover_image-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TITLE_FORM.FEEDBACK.cover_image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 1-->

        <!--begin: Form Wizard Step 2-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content">
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-tabs align="center" nav-class="nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success">
                <b-tab :title="$t('COMMON.TABLIST.TITLE.UG')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group :state="validateState('content', 'content', 'zh-UG')" aria-describedby="ug-content-live-feedback" id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.LABEL.content')" label-for="ug-content">
                        <simple-editor :debug="false" v-model="formData.content['zh-UG'].content" :only="['text', 'image']" />
                        <b-form-invalid-feedback id="ug-content-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.FEEDBACK.content') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('COMMON.TABLIST.TITLE.CN')">
                  <b-row>
                    <b-col sm="6" md="12">
                      <b-form-group :state="validateState('content', 'content', 'zh-CN')" aria-describedby="cn-content-live-feedback" id="input-group-cn-content" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.LABEL.content')" label-for="cn-content">
                        <simple-editor v-model="formData.content['zh-CN'].content" lang="zh-CN" :only="['text', 'image']" />
                        <b-form-invalid-feedback id="cn-content-live-feedback">
                          {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.CONTENT_FORM.FEEDBACK.content') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 2-->

        <!--begin: Form Wizard Step 3-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-patient-id_card" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.id_card')" label-for="patient-id_card">
                    <b-form-input id="patient-id_card" :class="[direction_ltr]" v-model="formData.patient.id_card" :state="validateState('id_card', 'patient')" aria-describedby="patient-id_card-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.id_card')" />
                    <b-form-invalid-feedback id="patient-id_card-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.FEEDBACK.id_card') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-patient-phone" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.phone')" label-for="patient-phone">
                    <b-form-input id="patient-phone" :class="[direction_ltr]" v-model="formData.patient.phone" :state="validateState('phone', 'patient')" aria-describedby="patient-phone-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.phone')" />
                    <b-form-invalid-feedback id="patient-phone-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.FEEDBACK.phone') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-gender" :label="$t('COMMON.FORM.LABEL.gender')" label-for="gender">
                    <b-form-radio-group
                        id="gender"
                        v-model="formData.patient.gender"
                        :options="genders"
                        buttons />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-ug-name" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.ug_name')" label-for="patient-ug-name">
                    <b-form-input id="patient-ug-name" :class="[direction_rtl]" v-model="formData.patient['zh-UG'].full_name" :state="validateState('full_name', 'patient', 'zh-UG')" aria-describedby="patient-ug-name-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.ug_name')" />
                    <b-form-invalid-feedback id="patient-ug-name-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.FEEDBACK.ug_name') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-cn-name" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.cn_name')" label-for="patient-cn-name">
                    <b-form-input id="patient-cn-name" :class="[direction_ltr]" v-model="formData.patient['zh-CN'].full_name" :state="validateState('full_name', 'patient', 'zh-CN')" aria-describedby="patient-cn-name-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.cn_name')" />
                    <b-form-invalid-feedback id="patient-cn-name-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.FEEDBACK.cn_name') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-ug-address" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.ug_address')" label-for="patient-ug-address">
                    <b-form-input id="patient-ug-address" :class="[direction_rtl]" v-model="formData.patient.other.address['zh-UG']" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.ug_address')" />
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-cn-address" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.LABEL.cn_address')" label-for="patient-cn-address">
                    <b-form-input id="patient-cn-address" :class="[direction_ltr]" v-model="formData.patient.other.address['zh-CN']" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PATIENT_FORM.PLACEHOLDER.cn_address')" />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 3-->

        <!--begin: Form Wizard Step 4-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-patient_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.LABEL.patient_id')" label-for="patient_id">
                    <b-form-input id="patient_id" :class="[direction_ltr]" v-model="formData.hospital.patient_id" :state="validateState('patient_id', 'hospital')" aria-describedby="patient_id-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.patient_id')" />
                    <b-form-invalid-feedback id="patient_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.FEEDBACK.patient_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-hospital_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.LABEL.hospital_id')" label-for="hospital_id" >
                    <b-form-select id="hospital_id" v-model="formData.hospital.hospital_id" :options="hospitalOptions" :state="validateState('hospital_id', 'hospital')" aria-describedby="hospital_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.hospital_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.hospital_id') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="hospital_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.FEEDBACK.hospital_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-department_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.LABEL.department_id')" label-for="department_id" >
                    <b-form-select id="department_id" v-model="formData.hospital.hospital_department_id" :options="departmentOptions" :state="validateState('hospital_department_id', 'hospital')" aria-describedby="department_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.department_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.department_id')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="department_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.FEEDBACK.department_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-ug-name" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.LABEL.address_ug')" label-for="patient-ug-name">
                    <b-form-input id="patient-ug-name" :class="[direction_rtl]" v-model="formData.hospital['zh-UG'].address" :state="validateState('address', 'hospital', 'zh-UG')" aria-describedby="patient-ug-name-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.address_ug')" />
                    <b-form-invalid-feedback id="patient-ug-name-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.FEEDBACK.address_ug') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-patient-cn-name" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.LABEL.address_cn')" label-for="patient-cn-name">
                    <b-form-input id="patient-cn-name" :class="[direction_ltr]" v-model="formData.hospital['zh-CN'].address" :state="validateState('address', 'hospital', 'zh-CN')" aria-describedby="patient-cn-name-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.PLACEHOLDER.address_cn')" />
                    <b-form-invalid-feedback id="patient-cn-name-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.HOSPITAL_FORM.FEEDBACK.address_cn') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 4-->

        <!--begin: Form Wizard Step 5-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="3">
                  <b-form-group id="input-group-gender" :state="validateState('banner_type', 'banner')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.banner_type')" label-for="banner_type" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.banner_type')">
                    <b-form-radio-group
                      id="gender"
                      v-model="formData.banner.banner_type"
                      :options="bannerOptions"
                      @change="onChangeBannerType"
                      buttons />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="12" v-if="formData.banner.banner_type === 'image'">
                  <b-form-group id="input-group-banners" :state="validateState('banners', 'banner')" aria-describedby="banners-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.image')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.image')" label-for="banners">
                    <multi-uploader v-model="formData.banner.banners" :data="{strategy: 'salon'}" :limit="4" />
                    <b-form-invalid-feedback id="banners-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.FEEDBACK.image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="12" v-if="formData.banner.banner_type === 'video'">
                  <b-form-group id="input-group-banners" :state="validateState('banners', 'banner')" aria-describedby="banners-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.video')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.PLACEHOLDER.video')" label-for="banners">
                    <multi-uploader video v-model="formData.banner.banners" :data="{strategy: 'video'}" :limit="1" list-type="text" accept="video/*, video/x-msvideo, video/mpeg, video/3gpp, video/mp4" />
                    <b-form-invalid-feedback id="banners-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.FEEDBACK.video') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 5-->

        <!--begin: Form Wizard Step 6-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.EVIDENCES_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="12" >
                  <b-form-group id="input-group-evidence_images" :state="validateState('evidence_images', 'evidence')" aria-describedby="evidence_images-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.EVIDENCES_FORM.LABEL.image')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.EVIDENCES_FORM.PLACEHOLDER.image')" label-for="evidence_images">
                    <multi-uploader v-model="formData.evidence.evidence_images" :data="{strategy: 'salon'}" :limit="12" />
                    <b-form-invalid-feedback id="evidence_images-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.EVIDENCES_FORM.FEEDBACK.image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 6-->

        <!--begin: Form Wizard Step 7-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PERSONAL_IMAGE_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="12" >
                  <b-form-group id="input-group-personal_images" :state="validateState('personal_images', 'personal')" aria-describedby="personal_images-live-feedback" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PERSONAL_IMAGE_FORM.LABEL.image')" :description="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PERSONAL_IMAGE_FORM.PLACEHOLDER.image')" label-for="personal_images">
                    <multi-uploader v-model="formData.personal.personal_images" :data="{strategy: 'salon'}" :limit="8" />
                    <b-form-invalid-feedback id="personal_images-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PERSONAL_IMAGE_FORM.FEEDBACK.image') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 7-->

        <!--begin: Form Wizard Step 8-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-house_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.house_id')" label-for="house_id" >
                    <b-form-select id="house_id" v-model="formData.public_information.house_id" :options="houseOptions" :state="validateState('house_id', 'public_information')" aria-describedby="house_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.house_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.house_id') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="house_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.house_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-car_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.car_id')" label-for="car_id" >
                    <b-form-select id="car_id" v-model="formData.public_information.car_id" :options="carOptions" :state="validateState('car_id', 'public_information')" aria-describedby="car_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.car_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.car_id') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="car_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.car_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-job_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.job_id')" label-for="job_id" >
                    <b-form-select id="car_id" v-model="formData.public_information.job_id" :options="jobOptions" :state="validateState('job_id', 'public_information')" aria-describedby="job_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.job_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.job_id') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="job_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.job_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <b-form-group id="input-group-source_income_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.source_income_id')" label-for="source_income_id" >
                    <b-form-select id="source_income_id" v-model="formData.public_information.source_income_id" :options="sourceIncomeOptions" :state="validateState('source_income_id', 'public_information')" aria-describedby="source_income_id-live-feedback" value-field="id" :text-field="`${$i18n.locale}.title`" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.source_income_id')">
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.PLACEHOLDER.source_income_id') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="source_income_id-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.source_income_id') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="4">
                  <app-slider :max="200000" :step="10000" id="income" v-model="formData.public_information.income" :suffix="$t('COMMON.$')" :state="validateState('income', 'public_information')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.income')" :feedback="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.income')" />
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" md="4">
                  <app-slider :max="200000" :step="10000" id="debt" v-model="formData.public_information.debt"  :suffix="$t('COMMON.$')" :state="validateState('debt', 'public_information')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.debt')" :feedback="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.debt')" />
                </b-col>
                <b-col sm="6" md="4">
                  <app-slider :max="200000" :step="10000" id="medical_expense" v-model="formData.public_information.medical_expense"  :suffix="$t('COMMON.$')" :state="validateState('medical_expense', 'public_information')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.medical_expense')" :feedback="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.medical_expense')" />
                </b-col>
                <b-col sm="6" md="4">
                  <app-slider :max="1000000" :step="10000" id="amount_raised" v-model="formData.public_information.amount_raised"  :suffix="$t('COMMON.$')" :state="validateState('amount_raised', 'public_information')" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.LABEL.amount_raised')" :feedback="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.PUBLIC_INFORMATION_FORM.FEEDBACK.amount_raised')" />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 8-->

        <!--begin: Form Wizard Step 9-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content" >
          <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.HEADING_TITLE") }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__form">
              <b-row>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-regulator_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.regulator_id')" label-for="regulator_id">
                    <b-input-group>
                      <b-form-input :state="validateState('regulator_id', 'manager')" aria-describedby="regulator_id-live-feedback" readonly :value="regulatorText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.regulator_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="regulator_id" @click="showOrganization('regulator')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="regulator_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.regulator_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-actuator_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.actuator_id')" label-for="actuator_id">
                    <b-input-group>
                      <b-form-input :state="validateState('actuator_id', 'manager')" aria-describedby="actuator_id-live-feedback" readonly :value="actuatorText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.actuator_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="actuator_id" @click="showOrganization('actuator')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="actuator_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.actuator_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-donation_manager_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.donation_manager_id')" label-for="donation_manager_id">
                    <b-input-group>
                      <b-form-input :state="validateState('donation_manager_id', 'manager')" aria-describedby="donation_manager_id-live-feedback" readonly :value="donationText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.donation_manager_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="donation_manager_id" @click="showOrganization('donation')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="donation_manager_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.donation_manager_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-project_leader_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.project_leader_id')" label-for="project_leader_id">
                    <b-input-group>
                      <b-form-input :state="validateState('project_leader_id', 'manager')" aria-describedby="project_leader_id-live-feedback" readonly :value="projectLeaderText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.project_leader_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="project_leader_id" @click="showOrganization('leader')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="project_leader_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.project_leader_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6" >
                  <b-form-group id="input-group-merchant_id" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.merchant_id')" label-for="merchant_id">
                    <b-input-group>
                      <b-form-input :state="validateState('merchant_id', 'manager')" aria-describedby="merchant_id-live-feedback" readonly :value="merchantText" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.merchant_id')"></b-form-input>
                      <b-input-group-append>
                        <b-button id="merchant_id" @click="showOrganization('merchant')">{{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.BUTTON.CHOOSE') }}</b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="merchant_id-live-feedback">
                        {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.FEEDBACK.merchant_id') }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" md="6">
                  <b-form-group id="input-group-record_number" :label="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number')" label-for="record_number">
                    <b-form-input id="record_number" :class="[direction_ltr]" v-model="formData.manager.record_number" :state="validateState('record_number', 'manager')" aria-describedby="record_number-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number')" />
                    <b-form-invalid-feedback id="record_number-live-feedback">
                      {{ $t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.LABEL.record_number') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 9-->

        <!--begin: Form Wizard Step 10-->
        <div class="kt-wizard-v2__content" data-ktwizard-type="step-content">
          <div class="kt-heading kt-heading--md">{{ formData.title[$i18n.locale].title }}</div>
          <div class="kt-form__section kt-form__section--first">
            <div class="kt-wizard-v2__review">
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.name") }}: {{ formData.patient[$i18n.locale].full_name }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.id_card") }}: {{ formData.patient.id_card }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.phone") }}: {{ formData.patient.phone }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.gender") }}: {{ formData.patient.gender === 1 ? $t('COMMON.FORM.OPTIONS.MALE') : $t('COMMON.FORM.OPTIONS.FEMALE') }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PATIENT.address") }}: {{ formData.patient.other.address[lang] }}<br />
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.HOSPITAL.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.HOSPITAL.patient_id") }}: {{ formData.hospital.patient_id }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.HOSPITAL.hospital_id") }}: {{ hospitalTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.HOSPITAL.department_id") }}: {{ departmentTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.HOSPITAL.address") }}: {{ formData.hospital[$i18n.locale].address }}<br />
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.BANNER.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  <template v-if="formData.banner.banner_type === 'image'">
                    <b-avatar-group size="60" rounded overlap="-0.6">
                      <b-avatar v-for="banner in formData.banner.banners" :src="banner.url" :key="banner.path" text="!"></b-avatar>
                    </b-avatar-group>
                  </template>
                  <template v-else-if="formData.banner.banner_type === 'video'">
                    <video class="video-player" controls v-if="formData.banner.banners.length" :src="generateVideoUrl(formData.banner.banners[0].path)"></video>
                  </template>
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.EVIDENCE_IMAGE.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  <b-avatar-group size="60" rounded overlap="-0.6">
                    <b-avatar v-for="banner in formData.evidence.evidence_images" :src="banner.url" :key="banner.path" text="!"></b-avatar>
                  </b-avatar-group>
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PERSONAL_IMAGE.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  <b-avatar-group size="60" rounded overlap="-0.6">
                    <b-avatar v-for="banner in formData.personal.personal_images" :src="banner.url" :key="banner.path" text="!"></b-avatar>
                  </b-avatar-group>
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.house_id") }}: {{ houseTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.car_id") }}: {{ carTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.job_id") }}: {{ jobTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.income") }}: {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.symbol", {price: formData.public_information.income}) }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.source_income_id") }}: {{ incomeTitle }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.debt") }}: {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.symbol", {price: formData.public_information.debt}) }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.medical_expense") }}: {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.symbol", {price: formData.public_information.medical_expense}) }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.amount_raised") }}: {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.PUBLIC_INFORMATION.symbol", {price: formData.public_information.amount_raised}) }}<br />
                </div>
              </div>
              <div class="kt-wizard-v2__review-item">
                <div class="kt-wizard-v2__review-title">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.title") }}
                </div>
                <div class="kt-wizard-v2__review-content">
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.regulator_id") }}: {{ regulatorText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.actuator_id") }}: {{ actuatorText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.project_leader_id") }}: {{ projectLeaderText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.donation_manager_id") }}: {{ donationText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.merchant_id") }}: {{ merchantText }}<br />
                  {{ $t("COMPONENTS.FORMS.ARTICLE_RAISE_FORM.REVIEW.MANAGER.record_number") }}: {{ formData.manager.record_number }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Step 10-->

        <!--begin: Form Actions -->
        <div class="kt-form__actions">
          <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
            {{ $t("COMMON.FORM.BUTTONS.PREV") }}
          </button>
          <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
            {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
          </button>
          <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
            {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
          </button>
        </div>

        <!--end: Form Actions -->
      </div>

      <!--end: Form Wizard Form-->
    </div>
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.regulator_id')" id="choose-regulator" :options="regulatorOptions" v-model="regulatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" @change="onChangeRegulator" image-field="logo_image_preview" :form-value="regulator_id" name="regulator_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.donation_manager_id')" id="choose-donation-manager" :options="regulatorOptions" v-model="donationManagerModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" image-field="logo_image_preview" @change="onChangeDonationManager" :form-value="donation_manager_id" name="donation_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.actuator_id')" id="choose-actuator" :options="actuatorOptions" v-model="actuatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" image-field="logo_image_preview" @change="onChangeActuatorManager" :form-value="actuator_id" name="actuator_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.project_leader_id')" id="choose-project-leader" :options="projectLeadersOptions" v-model="projectLeaderModalState" :title-field="`${$i18n.locale}.full_name`" :describe-field="`${$i18n.locale}.describe`" image-field="avatar_image_preview" @change="onChangeProjectLeaderManager" :form-value="project_leader_id" name="project_leader_option" />
    <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.merchant_id')" id="choose-merchant_id" :options="merchantOptions" v-model="merchantModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" sub-title-field="mch_id" @change="onChangeMerchantManager" :form-value="merchant_id" name="merchant_option" />
  </div>
</template>

<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import MultiUploader from "@v@/components/MultiUploader";
  import SimpleEditor from "@v@/components/SimpleEditor";
  import AppSlider from "@v@/components/AppSlider";
  import ChooseOrganizationModal from "@v@/components/modals/choose.organization.modal";
  import MiniProgramScanAppCode from "@v@/components/user/MiniProgramScanAppCode";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import articleMixin from "@m@/article.mixin";
  import directionMixin from "@m@/common/direction.mixin";
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  import {mapGetters} from "vuex";
  import ApiService from "@/common/api.service";
  export default {
    name: "index",
    components: {ImageUploader, SimpleEditor, MultiUploader, AppSlider, ChooseOrganizationModal, MiniProgramScanAppCode},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
      rid: {
        type: [String, Number],
      },
    },
    mixins: [articleMixin, validateStateMixin, validationMixin, sweetAlertMixin, directionMixin],
    created() {
      this.getArticleCoreData();
      if (this.action === 'edit'){
        this.getArticleSubject(this.id, this.rid)
          .then((res)=>{
            let { version: onlineVersion, subject: { version } } = res.data;
            if( onlineVersion > version ){
              return this.$router.replace({ name: "article.versions", params: { id: this.id } });
            }
            this.formData = Object.assign({}, this.formData, this.articleSubject);
            if( this.formData.patient.hasOwnProperty('other') && ! this.formData.patient.other ){
              this.formData.patient.other = {
                address: {
                  "zh-UG": null,
                  "zh-CN": null
                }
              };
            }
            this.setArticleLockState(this.rid, 1);
          })
          .catch(err=>{
            console.info('getArticleSubject.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    destroyed() {
      if (this.action === 'edit' ){
        this.setArticleLockState(this.rid, 0);
      }
    },
    computed: {
      ...mapGetters(['articleCoreData', 'articleSubject']),
      lang(){
        const { locale } = this.$i18n
        return locale
      },
      regulator_id(){
        const { manager: {regulator_id} } = this.formData;
        return regulator_id
      },
      donation_manager_id(){
        const { manager: {donation_manager_id} } = this.formData;
        return donation_manager_id
      },
      actuator_id(){
        const { manager: {actuator_id} } = this.formData;
        return actuator_id
      },
      project_leader_id(){
        const { manager: {project_leader_id} } = this.formData;
        return project_leader_id
      },
      merchant_id(){
        const { manager: {merchant_id} } = this.formData;
        return merchant_id
      },
      hospitalOptions(){
        let { hospital = [] } = this.articleCoreData || {};
        return hospital;
      },
      departmentOptions(){
        let { department = [] } = this.articleCoreData || {};
        return department;
      },
      houseOptions(){
        let { house = [] } = this.articleCoreData || {};
        return house;
      },
      jobOptions(){
        let { job = [] } = this.articleCoreData || {};
        return job;
      },
      carOptions(){
        let { car = [] } = this.articleCoreData || {};
        return car;
      },
      sourceIncomeOptions(){
        let { source_income = [] } = this.articleCoreData || {};
        return source_income;
      },
      regulatorOptions(){
        let { regulators = [] } = this.articleCoreData || {};
        return regulators;
      },
      actuatorOptions(){
        let { actuators = [] } = this.articleCoreData || {};
        return actuators;
      },
      projectLeadersOptions(){
        let { project_leaders = [] } = this.articleCoreData || {};
        return project_leaders;
      },
      merchantOptions(){
        let { merchants = [] } = this.articleCoreData || {};
        return merchants;
      },
      regulatorText(){
        let { regulator_id } = this.formData.manager;
        if( regulator_id ){
          let { regulatorOptions } = this;
          return this.getProperty(regulatorOptions, regulator_id);
        }
        return '';
      },
      donationText(){
        let { donation_manager_id } = this.formData.manager;
        if( donation_manager_id ){
          let { regulatorOptions } = this;
          return this.getProperty(regulatorOptions, donation_manager_id);
        }
        return '';
      },
      actuatorText(){
        let { actuator_id } = this.formData.manager;
        if( actuator_id ){
          let { actuatorOptions } = this;
          return this.getProperty(actuatorOptions, actuator_id);
        }
        return '';
      },
      projectLeaderText(){
        let { project_leader_id } = this.formData.manager;
        if( project_leader_id ){
          let { projectLeadersOptions } = this;
          return this.getProperty(projectLeadersOptions, project_leader_id, 'full_name');
        }
        return '';
      },
      merchantText(){
        let { merchant_id } = this.formData.manager;
        if( merchant_id ){
          let { merchantOptions } = this;
          return this.getProperty(merchantOptions, merchant_id);
        }
      },
      hospitalTitle(){
        let { hospital_id } = this.formData.hospital;
        if( hospital_id ){
          let { hospitalOptions } = this;
          return this.getProperty(hospitalOptions, hospital_id);
        }
      },
      departmentTitle(){
        let { hospital_department_id } = this.formData.hospital;
        if( hospital_department_id ){
          let { departmentOptions } = this;
          return this.getProperty(departmentOptions, hospital_department_id);
        }
      },
      houseTitle(){
        let { house_id } = this.formData.public_information;
        if( house_id ){
          let { houseOptions } = this;
          return this.getProperty(houseOptions, house_id);
        }
      },
      carTitle(){
        let { car_id } = this.formData.public_information;
        if( car_id ){
          let { carOptions } = this;
          return this.getProperty(carOptions, car_id);
        }
      },
      jobTitle(){
        let { job_id } = this.formData.public_information;
        if( job_id ){
          let { jobOptions } = this;
          return this.getProperty(jobOptions, job_id);
        }
      },
      incomeTitle(){
        let { source_income_id } = this.formData.public_information;
        if( source_income_id ){
          let { sourceIncomeOptions } = this;
          return this.getProperty(sourceIncomeOptions, source_income_id);
        }
      },
    },
    watch: {
      modelData: {
        handler(v){
          if( v && v.hasOwnProperty('id') ){
            this.formData = {...this.formData, ...this.modelData}
          }
        },
        immediate: true
      }
    },
    validations(){
      let validates = {
        cover_image: { required },
      };
      if( this.action === 'create' ){
        validates.user_id = { required }
      }
      validates.title = {
        "zh-UG": {
          title: { required }
        },
        "zh-CN": {
          title: { required }
        }
      };
      validates.content = {
        "zh-UG": {
          content: {required}
        },
        "zh-CN": {
          content: {required}
        }
      };
      validates.patient = {
        id_card: { required },
        phone: { required },
        "zh-UG": {
          full_name: { required },
        },
        "zh-CN": {
          full_name: { required },
        }
      };
      validates.hospital = {
        patient_id: { required },
        hospital_id: { required },
        hospital_department_id: { required },
        "zh-UG": {
          address: { required },
        },
        "zh-CN": {
          address: { required },
        }
      };
      validates.banner = {
        banners: { required },
        banner_type: { required }
      };
      validates.evidence = {
        evidence_images: { required }
      };
      validates.personal = {
        personal_images: { required }
      };
      validates.public_information = {
        house_id: { required },
        car_id: { required },
        job_id: { required },
        income: { required },
        source_income_id: { required },
        debt: { required },
        medical_expense: { required },
        amount_raised: { required },
      };
      validates.manager = {
        regulator_id: { required },
        actuator_id: { required },
        project_leader_id: { required },
        donation_manager_id: { required },
        record_number: { required },
        merchant_id: { required },
      };
      return {
        formData: validates
      }
    },
    data() {
      return {
        wizard: null,
        loading: false,
        regulatorModalState: false,
        actuatorModalState: false,
        projectLeaderModalState: false,
        donationManagerModalState: false,
        merchantModalState: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        genders: [
          { text: this.$t('COMMON.FORM.OPTIONS.MALE'), value: 1 },
          { text: this.$t('COMMON.FORM.OPTIONS.FEMALE'), value: 2 },
        ],
        bannerOptions: [
          { text: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.image_type'), value: 'image' },
          { text: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.BANNER_FORM.LABEL.video_type'), value: 'video' },
        ],
        formData: {
          type: 'raise',
          cover_image: null,
          title: {
            'zh-UG': {
              title: "",
              title_prefix: null,
              describe: "",
            },
            'zh-CN': {
              title: "",
              title_prefix: null,
              describe: "",
            },
          },
          content: {
            'zh-UG': {
              content: []
            },
            'zh-CN': {
              content: []
            },
          },
          patient: {
            'zh-UG':{
              full_name: '', // 患者姓名
            },
            'zh-CN':{
              full_name: '', // 患者姓名
            },
            id_card: '', // 患者身份证号
            phone: '', // 联系电话
            gender: '', // 患者性别
            other: {
              address: {
                'zh-UG': null,
                'zh-CN': null
              }
            }
          },
          hospital: {
            hospital_id: null, // 医院id
            hospital_department_id: null, // 科室名称
            patient_id: null, // 住院号
            "zh-UG": {
              address: null
            },
            "zh-CN": {
              address: null
            }
          },
          banner: {
            banners: [], // banner 数据集
            banner_type: '' // banner类型
          },
          evidence: {
            evidence_images: [] // 证明材料
          },
          personal: {
            personal_images: [] // 个人照片
          },
          public_information: {
            house_id: null, // 房子
            car_id: null, // 汽车
            job_id: null, // 工作
            income: 0, // 年收入
            source_income_id: null, // 收入来源
            debt: 0, // 债务
            medical_expense: 0, // 自掏腰包的医疗费用
            amount_raised: 0 // 筹款总额
          },
          manager: {
            regulator_id: null,
            actuator_id: null,
            project_leader_id: null,
            donation_manager_id: null,
            merchant_id: null,
            record_number: null,
          },
          sort: null,
          user_id: null
        },
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeArticle(this.formData)
            .then(()=> {
              this.$router.replace({ name: `article.home` });
            }).catch(err=>{
              console.info('storeArticle.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          delete this.formData.lang;
          this.updateArticle(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateArticle.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v2', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', wizardObj){
        this.$v.formData[formName].$touch();
        if (this.$v.formData[formName].$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          // wizardObj.goNext();
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        const { action } = this;
        switch (currentStep) {
          case (action === 'create' ? 1 : 0):
            this.validHandler('user_id', wizardObj);
            break;
          case (action === 'create' ? 2 : 1):
            this.validHandler('title', wizardObj);
            this.validHandler('cover_image', wizardObj);
            break;
          case (action === 'create' ? 3 : 2):
            this.validHandler('content', wizardObj);
            break;
          case (action === 'create' ? 4 : 3):
            this.validHandler('patient', wizardObj);
            break;
          case (action === 'create' ? 5 : 4):
            this.validHandler('hospital', wizardObj);
            break;
          case (action === 'create' ? 6 : 5):
            this.validHandler('banner', wizardObj);
            break;
          case (action === 'create' ? 7 : 6):
            this.validHandler('evidence', wizardObj);
            break;
          case (action === 'create' ? 8 : 7):
            this.validHandler('personal', wizardObj);
            break;
          case (action === 'create' ? 9 : 8):
            this.validHandler('public_information', wizardObj);
            break;
          case (action === 'create' ? 10 : 9):
            this.validHandler('manager', wizardObj);
            break;
        }
      },
      onChangeBannerType(){
        this.formData.banner.banners = [];
      },
      showOrganization(type = 'regulator'){
        switch (type) {
          case "regulator":
            this.regulatorModalState = true;
            break;
          case "actuator":
            this.actuatorModalState = true;
            break;
          case "leader":
            this.projectLeaderModalState = true;
            break;
          case "donation":
            this.donationManagerModalState = true;
            break;
          case "merchant":
            this.merchantModalState = true;
            break;
        }
      },
      hideOrganization(type = 'regulator'){
        switch (type) {
          case "regulator":
            this.regulatorModalState = false;
            break;
          case "actuator":
            this.actuatorModalState = false;
            break;
          case "leader":
            this.projectLeaderModalState = false;
            break;
          case "donation":
            this.donationManagerModalState = false;
            break;
          case "merchant":
            this.merchantModalState = false;
            break;
        }
      },
      onChangeMerchantManager(e){
        this.formData.manager.merchant_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('merchant');
        })
      },
      onChangeRegulator(e){
        this.formData.manager.regulator_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('regulator');
        })
      },
      onChangeDonationManager(e){
        this.formData.manager.donation_manager_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('donation');
        })
      },
      onChangeActuatorManager(e){
        this.formData.manager.actuator_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('actuator');
        })
      },
      onChangeProjectLeaderManager(e){
        this.formData.manager.project_leader_id = e;
        this.$nextTick(()=>{
          this.hideOrganization('leader');
        })
      },
      getProperty(obj, id, column = 'title'){
        let { locale } = this.$i18n;
        let result = Object.assign({}, ...obj.filter(item=> item.id == id));
        return result.hasOwnProperty(locale) ? result[locale][column] : '' ;
      },
      onAuthSuccess(user){
        this.formData.user_id = user.id;
      },
      generateVideoUrl(path = ''){
        return ApiService.generateUrl('manager/fetch', `file=${path}`)
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-2";
  .b-avatar-group-inner {
    gap: 10px;
  }

  .video-player {
    height: 150px;
    width: 300px;
  }
</style>
