<template>
  <portlet :body-class="`border-bottom border-bottom-4 ${subjectStatus == 1 ? 'border-primary' : ''} ${subjectStatus == 2 ? 'border-success' : ''} ${subjectStatus == 3 ? 'border-danger' : ''}`">
    <template v-slot:body>
      <div class="kt-widget kt-widget--user-profile-3">
        <div class="kt-widget__top">
          <div class="kt-widget__media">
            <b-avatar :src="article.cover_image_preview || ''" text="!" size="100" rounded />
          </div>
          <div class="kt-widget__content">
            <div class="kt-widget__head">
              <a class="kt-widget__username cursor-default" v-b-tooltip :title="statusReason"> {{ title }} </a>
              <div class="kt-widget__action">
                <b-badge size="sm" variant="info" v-if="article.version < article.raises_count">{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.INFO.PENDING_REVIEW') }}</b-badge>
                <b-badge size="sm" variant="warning" v-if="!article.audit">{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.INFO.PENDING_AUDIT') }}</b-badge>
                <b-dropdown no-caret size="sm" :variant="`label-${subjectStatus == 1 ? 'primary' : ''}${subjectStatus == 2 ? 'success' : ''}${subjectStatus == 3 ? 'danger' : ''}`" class="btn-upper" :text="$t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.TEXT')">
                  <b-dropdown-item :to="{name: 'article.versions', params: {id: article.id, type: article.type}}"><i class="la la-edit"></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.DESCRIPTION') }}</b-dropdown-item>
                  <b-dropdown-item v-if="currentAdmin.super" @click="onClickAllocation('regulator')"><i class="flaticon-interface-1"></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.ALLOCATION') }}</b-dropdown-item>
                  <b-dropdown-item v-else-if="!currentAdmin.sub_account" @click="onClickAllocation('actuator')"><i class="flaticon-interface-1"></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.ALLOCATION') }}</b-dropdown-item>
                  <b-dropdown-item @click="onClickSubjectStatus"><i class="fa fa-exclamation-triangle"></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.STATUS') }}</b-dropdown-item>
                  <b-dropdown-item :to="{name: 'article.statistics', params: {id: article.id}}"><i class="flaticon2-pie-chart-1" ></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.STATISTICS') }}</b-dropdown-item>
                  <b-dropdown-item @click="onClickCopyLink(article)"><i class="fa fa-copy" ></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.COPY_LINK') }}</b-dropdown-item>
                  <b-dropdown-item @click="onClickCopyLink(article, 'h5')"><i class="fab fa-internet-explorer" ></i>{{ $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.COPY_H5_LINK') }}</b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item :to="{name: 'article.notifications', params: { id: article.id}}"><i class="la la-bullhorn" ></i>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.ALL_NOTIFICATION") }}</b-dropdown-item>
                  <b-dropdown-item :to="{name: 'article.notifications.store', params: { id: article.id}}"><i class="la la-plus-circle" ></i>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.ADD_NOTIFICATION") }}</b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item :to="{name: 'article.progress-history', params: { id: article.id}}"><i class="fa fa-hand-holding-heart" ></i>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.PROGRESS_HISTORY") }}</b-dropdown-item>
                  <b-dropdown-item :to="{name: 'comment.list', params: {id: article.id} }"><i class="la la-comment" ></i>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.ACTIONS.COMMENT") }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="kt-widget__subhead">
              <a href="javascript:;" class="cursor-default text-danger"><i class="fa fa-bell" ></i><span>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.LABEL.TITLE") }}</span></a>
            </div>
            <div class="kt-widget__info">
              <div class="kt-widget__desc cursor-default">
                {{ describe }}
              </div>
              <div class="kt-widget__progress">
                <b-progress style="height: 5px;" class="w-100" :animated="subjectStatus == 1" :variant="`${subjectStatus == 1 ? 'primary' : ''}${subjectStatus == 2 ? 'success' : ''}${subjectStatus == 3 ? 'danger' : ''}`" :max="100" :value="percentage" />
                <div class="kt-widget__stats">
                  {{ percentage }}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="kt-widget__bottom">
          <div class="kt-widget__item">
            <div class="kt-widget__icon">
              <i class="flaticon-notepad"></i>
            </div>
            <div class="kt-widget__details">
              <span class="kt-widget__title">{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.RAISE") }}</span>
              <span class="kt-widget__value"><span>￥</span>{{ targetPrice }}</span>
            </div>
          </div>
          <div class="kt-widget__item">
            <div class="kt-widget__icon">
              <i class="fa fa-hand-holding-heart" style="font-weight: 600"></i>
            </div>
            <div class="kt-widget__details">
              <span class="kt-widget__title">{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.TOTAL") }}</span>
              <span class="kt-widget__value"><span>￥</span>{{ totalFee }}</span>
            </div>
          </div>
          <div class="kt-widget__item">
            <div class="kt-widget__icon">
              <i class="flaticon-security"></i>
            </div>
            <div class="kt-widget__details">
              <span class="kt-widget__title">{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.GROUP") }}</span>
              <span class="kt-widget__value" v-if="article.groups_count">{{ article.groups_count }}<span>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.GROUP_LIMIT_SYMBOL") }}</span></span>
              <span class="kt-widget__value" v-else>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.NO_GROUP") }}</span>
            </div>
          </div>
          <div class="kt-widget__item">
            <div class="kt-widget__icon">
              <i class="flaticon2-chat"></i>
            </div>
            <div class="kt-widget__details">
              <span class="kt-widget__title" v-if="commentsCount">{{commentsCount}} {{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.COMMENTS_COUNT") }}</span>
              <span class="kt-widget__title" v-else>{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.NO_COMMENTS") }}</span>
              <router-link :to="{name: 'comment.list', params: {id: article.id} }" class="kt-widget__value kt-font-brand" v-if="commentsCount">{{ $t("COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.COMMENT_MANAGER") }}</router-link>
            </div>
          </div>
          <div class="kt-widget__item" v-if="orders.length">
            <div class="kt-widget__icon">
              <i class="fa fa-users" style="font-weight: 600"></i>
            </div>
            <div class="kt-widget__details" v-b-tooltip :title="` ${orderUserCount + $t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.FOOTER.DONORS_COUNT')}`">
              <div class="kt-section__content kt-section__content--solid">
                <div class="kt-media-group">
                  <b-avatar v-for="order in orders" :key="order.id" class="kt-media kt-media--sm kt-media--circle" :src="order.user ? order.user.avatar : ''" text="!" />
                  <span v-if="orderUserCount > 5" class="kt-media kt-media--sm kt-media--circle cursor-default" style="height: 32px; line-height: 30px;padding-inline-end: 5px;" :class="[$i18n.locale === 'zh-UG' ? 'direction-rtl' : '']">{{ orderUserCount - 5 }}+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.regulator_id')" id="choose-regulator" :form-value="regulatorId" :options="regulatorOptions" v-model="regulatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" @change="onChangeRegulator" image-field="logo_image_preview" name="regulator_option" />
      <choose-organization-modal :title="$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.MANAGER_FORM.PLACEHOLDER.actuator_id')" id="choose-actuator" :form-value="actuatorId" :options="actuatorOptions" v-model="actuatorModalState" :title-field="`${$i18n.locale}.title`" :describe-field="`${$i18n.locale}.describe`" image-field="logo_image_preview" @change="onChangeActuator" name="actuator_option" />
    </template>
  </portlet>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import ChooseOrganizationModal from "@v@/components/modals/choose.organization.modal";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import articleMixin from "@m@/article.mixin";
  import {mapGetters} from 'vuex';
  export default {
    name: "index",
    props: {
      article: {
        type: Object,
        default: ()=> {return {}}
      }
    },
    components: {Portlet, ChooseOrganizationModal},
    mixins: [sweetAlertMixin, articleMixin],
    mounted() {
    },
    created() {},
    computed: {
      ...mapGetters(['currentAdmin', 'articleCoreData', 'otherSetting']),
      lang(){
        return this.$i18n.locale;
      },
      title(){
        let { lang } = this;
        let { title } = this.subject || {};
        return title[lang] && title[lang] || this.$t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.LABEL.NO_TITLE');
      },
      subject(){
        const { subject } = this.article || {};
        return subject || {};
      },
      subjectStatus(){
        let { status = 1 } = this.subject;
        return status
      },
      statusReason(){
        let { status_reason = {}, status } = this.subject ;
        if( status > 1 && status_reason ){
          const { lang } = this;
          return status_reason.hasOwnProperty(lang) ? status_reason[lang] : null;
        }
        return null;
      },
      describe(){
        let { lang } = this;
        let { subject: {title} } = this.article || {};
        return title[lang] && title[lang].describe || this.$t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.INFO.DESC');
      },

      targetPrice(){
        let { raise } = this.subject || {};
        return raise || 0.00;
      },
      totalFee(){
        let {order_total_fee} = this.article || {order_total_fee: 0.00};
        return order_total_fee || 0.00
      },
      commentsCount(){
        let { comments_count = 0 } = this.article || {};
        return comments_count
      },
      orderUserCount(){
        let {order_unique_users} = this.article || {order_unique_users: 0};
        return order_unique_users || 0
      },
      orders(){
        let {orders} = this.article || {orders: {}};
        return orders
      },
      percentage(){
        let {order_total_fee, subject: {raise}} = this.article || {order_total_fee: 0.00, subject: { raise: 10000.00}};
        order_total_fee = order_total_fee.replace(/,/g, "");
        return parseFloat((parseFloat(order_total_fee) / parseFloat(raise)) * 100).toFixed(3);
      },
      regulatorOptions(){
        let { regulators = [] } = this.articleCoreData || {};
        return regulators;
      },
      actuatorOptions(){
        let { actuators = [] } = this.articleCoreData || {};
        return actuators;
      },
      regulatorId(){
        const { regulator_id } = this.subject;
        return regulator_id || 0
      },
      actuatorId(){
        const { actuator_id } = this.subject;
        return actuator_id || 0
      },
      h5HomeUrl(){
        const { h5_home_url } = this.otherSetting || {};
        return h5_home_url || '';
      },
    },
    data() {
      return {
        loading: false,
        modalState: false,
        regulatorModalState: false,
        actuatorModalState: false,
      }
    },
    methods: {
      onClickSubjectStatus(){
        this.$emit('change-status', this.article);
      },
      onClickCopyLink(article, type = 'miniprogram'){
        const { encode_id } = article;
        const { lang } = this;
        let link = `/pages/index/index?target=project_show&target_id=${encode_id}&lang=${lang}`;
        if( type === 'h5' ){
          link = `${this.h5HomeUrl}${link}`;
        }
        if( this.$clipboard(link) ){
          this.success({ title: this.$t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.TIPS.COPY_SUCCESS') });
        }else{
          this.warning({ title: this.$t('COMPONENTS.ITEMS.ARTICLE.PROJECT_ITEM.TIPS.COPY_FAIL') });
        }
      },
      onClickAllocation(action = 'regulator'){
        if( action === 'regulator' ){
          this.regulatorModalState = true
        }else if( action === 'actuator'){
          this.actuatorModalState = true
        }
      },
      onChangeRegulator(v){
        this.setArticleAllocation(this.article.id, {action: 'regulator', id: v, type: 'project'})
          .finally(()=>{
            this.$nextTick(()=>{
              this.regulatorModalState = false
            });
          })
      },
      onChangeActuator(v){
        this.setArticleAllocation(this.article.id, {action: 'actuator', id: v, type: 'project'})
          .finally(()=>{
            this.$nextTick(()=>{
              this.actuatorModalState = false
            });
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .kt-widget__action {
    .badge {
      margin-inline-end: 5px;
      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

</style>
