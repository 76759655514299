<template>
  <base-modal :id="id" :title="title" :size="size" v-model="modalState" :modal-busy="busy">
    <b-pagination
            v-if="articlesMeta"
            v-model="articlesMeta.current_page"
            :total-rows="articlesMeta.total"
            :per-page="articlesMeta.per_page"
            @change="onChangePage" />
    <b-row>
      <b-col sm="6" md="6" v-for="option in articles" :key="option.id" class="d-flex">
        <app-option :form-value="form" :name="name" :option="option" :title-field="titleField" :value-field="valueField" :describe-field="describeField" :image-field="imageField" @change="onChangeValue" />
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import AppOption from "../AppOption";
  import Paginate from "@v@/components/paginate";
  import articleMixin from "@m@/article.mixin";
  import { mapGetters } from "vuex";
  import {debugConsole} from "@c@/helpers/helpers";
  export default {
    name: "ChooseArticleModal",
    components: { baseModal, AppOption, Paginate },
    mixins: [articleMixin],
    props: {
      id: {
        type: String,
        default: () => 'choose-organization-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'مەزمۇننى تاللاڭ'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      titleField: {
        type: String,
        default: ()=> 'title'
      },
      valueField: {
        type: String,
        default: ()=> 'id'
      },
      describeField: {
        type: String,
        default: ()=> 'describe'
      },
      imageField: {
        type: String
      },
      subTitleField: {
        type: String,
      },
      name: {
        type: String,
        default: ()=> 'o-option'
      },
      formValue: {
        type: [Number,String,Boolean,null]
      }
    },
    computed: {
      ...mapGetters(['articles', 'articlesMeta']),
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      formValue: {
        handler(v){
          this.form = v;
        }
      }
    },
    mounted() {
      this.form = this.formValue || 0;
      this.getArticles(1, {
        begin: ()=>{ this.busy = true; },
        after: (response, state)=>{ this.busy = false; },
      });
    },
    data(){
      return {
        form: 0,
        busy: false
      };
    },
    methods: {
      onChangeValue(e){
        this.$emit('change', e);
      },
      onChangePage(e){
        const { page } = e;
        this.getArticles(page, {
          begin: ()=>{ this.busy = true; },
          after: (response, state)=>{ this.busy = false; },
        });
      }
    }
  }
</script>

<style scoped>
  .pagination {
    padding-inline-start: 0;
  }
</style>
