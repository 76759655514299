export default {
  methods: {
    msgBoxConfirm( { message, title = this.$t("MSG_BOX.TITLE"), confirmCallback = null, confirmTitle = this.$t("MSG_BOX.CONFIRM_TEXT"), cancelTitle = this.$t("MSG_BOX.CANCEL_TEXT"), okVariant='danger' }){
      this.$bvModal.msgBoxConfirm(message, {
        title,
        okVariant,
        okTitle: confirmTitle,
        cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
      .then(value => {
        confirmCallback && confirmCallback(value);
      })
      .catch(err => {
        console.log(err)
        // An error occurred
      })
    }
  }
}
