<template>
  <base-modal :id="id" :title="title" :size="size" v-model="modalState" >
    <b-row>
      <b-col sm="6" md="6" v-for="option in options" :key="option.id" class="d-flex">
        <app-option :form-value="form" :name="name" :option="option" :title-field="titleField" :value-field="valueField" :describe-field="describeField" :image-field="imageField" :sub-title-field="subTitleField" @change="onChangeValue" />
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import AppOption from "../AppOption";
  export default {
    name: "ChooseOrganizationModal",
    components: { baseModal, AppOption },
    mixins: [],
    props: {
      id: {
        type: String,
        default: () => 'choose-organization-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'نازارەتچى ئورگاننى تاللاڭ'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      options: {
        type: Array,
        default: ()=> []
      },
      titleField: {
        type: String,
        default: ()=> 'title'
      },
      valueField: {
        type: String,
        default: ()=> 'id'
      },
      describeField: {
        type: String,
        default: ()=> 'describe'
      },
      imageField: {
        type: String
      },
      subTitleField: {
        type: String,
      },
      name: {
        type: String,
        default: ()=> 'o-option'
      },
      formValue: {
        type: [Number,String,Boolean,null]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      formValue: {
        handler(v){
          this.form = v;
        }
      }
    },
    mounted() {
      this.form = this.formValue || 0
    },
    data(){
      return {
        form: 0
      };
    },
    methods: {
      onChangeValue(e){
        this.$emit('change', e);
      },
    }
  }
</script>

<style scoped>

</style>
