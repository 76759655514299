<template>
  <upload
      class="my-3"
      :name="name"
      :data="data"
      :show-file-list="showFileList"
      :accept="accept"
      :list-type="listType"
      :file-list="value"
      :limit="limit"
      :headers="headers"
      :action="action"
      :on-success="onSuccess"
      :on-remove="onRemove"
      :on-exceed="onExceed"
      :on-preview="handlePreview"
  >
    <i class="fa fa-plus" slot="default"></i>
  </upload>
</template>

<script>
  import { Upload } from "element-ui";
  import {getToken} from "@c@/jwt.service";
  import {debugConsole} from "@c@/helpers/helpers";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";

  export default {
    name: "MultiUploader",
    components: { Upload },
    mixins: [sweetAlertMixin],
    props: {
      value: {
        type: Array,
        default: ()=> []
      },
      data: {
        type: Object,
        default: ()=>{return {}}
      },
      name: {
        type: String,
        default: ()=> 'file'
      },
      showFileList: {
        type: Boolean,
        default: ()=> true
      },
      accept: {
        type: String,
        default: ()=> "image/jpeg, image/png, image/jpg, image/gif"
      },
      listType: {
        type: String,
        default: ()=> "picture-card",
        validator: function (value) {
          return ['text', 'picture', 'picture-card'].includes(value)
        }
      },
      limit: {
        type: Number,
        default: ()=> 3
      },
      video: {
        type: Boolean
      }
    },
    computed: {
      action(){
        if( this.video )
          return `${process.env.VUE_APP_API_URL}/v1/manager/video-upload`;
        return `${process.env.VUE_APP_API_URL}/v1/manager/image-upload`;
      },
    },
    created() {
      this.images = Object.assign([], this.images, this.value )
    },
    watch: {
      value: {
        handler(v){
          if( v ){
            this.images = v;
          }
        },
        immediate: true
      }
    },
    data(){
      return {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        images: []
      }
    },
    methods: {
      onSuccess(response, file, fileList){
        debugConsole({ message: "onSuccess" })
        let images = this.images;
        images.push(response);
        this.$emit('input', images);
      },
      onRemove(file, fileList){
        this.$emit('input', fileList);
        this.images = fileList;
      },
      onExceed(files, fileList){
        let message = this.$t("COMPONENTS.MULTI_UPLOADER.LABEL.RATE_LIMIT", { limit: this.limit});
        this.warning({message});
      },
      handlePreview(file){
        const { url } = file;
        url && window.open(url);
      }
    }
  }
</script>

<style >
  .el-upload-list--picture-card .el-upload-list__item {
    margin: unset;
    margin-inline-end: 8px;
    margin-block-end: 8px;
  }
</style>
