<template>
  <div class="statistics-container">
    <b-overlay :show="basicQuantityLoading">
      <portlet body-fit>
        <template v-slot:body>
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col md="12" lg="6" xl="3">
              <!--begin::Total Profit-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donations") }}</h4>
                    <span class="kt-widget24__desc">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donationsDesc") }}</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ basicQuantity.user_counts || 0 }} {{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donationsSymbol") }}</span>
                </div>
              </div>
              <!--end::Total Profit-->
            </b-col>
            <b-col md="12" lg="6" xl="3">
              <!--begin::Total Profit-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donationTimes") }}</h4>
                    <span class="kt-widget24__desc">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donationTimesDesc") }}</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ basicQuantity.donation_counts || 0 }} {{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.donationTimesSymbol") }}</span>
                </div>
              </div>
              <!--end::Total Profit-->
            </b-col>
            <b-col md="12" lg="6" xl="3">
              <!--begin::Total Profit-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.highAmount") }}</h4>
                    <span class="kt-widget24__desc">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.highAmountDesc") }}</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ basicQuantity.max_fee || 0 }} {{ $t('COMMON.$')}}</span>
                </div>
              </div>
              <!--end::Total Profit-->
            </b-col>
            <b-col md="12" lg="6" xl="3">
              <!--begin::Total Profit-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.lowAmount") }}</h4>
                    <span class="kt-widget24__desc">{{ $t("PAGES.ARTICLE_STATISTICS.HOME.LABEL.lowAmountDesc") }}</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ basicQuantity.min_fee || 0 }} {{ $t('COMMON.$')}}</span>
                </div>
              </div>
              <!--end::Total Profit-->
            </b-col>
          </b-row>
          <div v-if="false" class="row row-no-padding row-col-separator-lg">
            <div class="col-md-12 col-lg-6 col-xl-3">
              <!--begin::Total Profit-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">
                      Total Profit
                    </h4>
                    <span class="kt-widget24__desc">
															All Customs Value
														</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">
														$18M
													</span>
                </div>
                <div class="progress progress--sm">
                  <div class="progress-bar kt-bg-brand" role="progressbar" style="width: 78%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="kt-widget24__action">
													<span class="kt-widget24__change">
														Change
													</span>
                  <span class="kt-widget24__number">
														78%
													</span>
                </div>
              </div>
              <!--end::Total Profit-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">

              <!--begin::New Feedbacks-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">
                      New Feedbacks
                    </h4>
                    <span class="kt-widget24__desc">
															Customer Review
														</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-warning">
														1349
													</span>
                </div>
                <div class="progress progress--sm">
                  <div class="progress-bar kt-bg-warning" role="progressbar" style="width: 84%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="kt-widget24__action">
													<span class="kt-widget24__change">
														Change
													</span>
                  <span class="kt-widget24__number">
														84%
													</span>
                </div>
              </div>

              <!--end::New Feedbacks-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">

              <!--begin::New Orders-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">
                      New Orders
                    </h4>
                    <span class="kt-widget24__desc">
															Fresh Order Amount
														</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger">
														567
													</span>
                </div>
                <div class="progress progress--sm">
                  <div class="progress-bar kt-bg-danger" role="progressbar" style="width: 69%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="kt-widget24__action">
													<span class="kt-widget24__change">
														Change
													</span>
                  <span class="kt-widget24__number">
														69%
													</span>
                </div>
              </div>

              <!--end::New Orders-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">

              <!--begin::New Users-->
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">
                      New Users
                    </h4>
                    <span class="kt-widget24__desc">
															Joined New User
														</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">
														276
													</span>
                </div>
                <div class="progress progress--sm">
                  <div class="progress-bar kt-bg-success" role="progressbar" style="width: 90%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="kt-widget24__action">
													<span class="kt-widget24__change">
														Change
													</span>
                  <span class="kt-widget24__number">
														90%
													</span>
                </div>
              </div>

              <!--end::New Users-->
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
    <b-row>
      <b-col sm="12" md="12">
        <article-statistics :id="id" @refresh="onRefreshStatistics" />
      </b-col>
    </b-row>
    <b-overlay :show="usersLoading">
    <b-row>
      <b-col class="d-flex" sm="12" md="3" v-for="user in statisticsUsers" :key="user.id">
        <portlet fluid-height body-fluid title=" " head-no-border head-size="sm" head-class="px-3" body-class="px-3 pt-0 pb-2">
          <template v-slot:toolbar>
            <b-button v-b-tooltip :title="$t('PAGES.ARTICLE_STATISTICS.HOME.LABEL.userDonations')" :to="{name: 'user.donations', params: {id: user.user.id}}" size="sm" variant=" " class="btn-clean btn-icon btn-icon-md"><i class="fa fa-id-card"></i></b-button>
          </template>
          <template v-slot:body>
            <div class="kt-widget-item-container">
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-avatar class="kt-widget__img" size="40" text="!" :src="user.user && user.user.avatar" />
                  </div>
                  <div class="kt-widget__info text-justify">
                    <a class="kt-widget__username">
                      {{ user.user && user.user.nickname }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact cursor-default">
                      <span class="kt-widget__label">{{ $t('PAGES.ARTICLE_STATISTICS.HOME.LABEL.order_total_fee') }}</span>
                      <span class="kt-widget__data">{{ user.order_total_fee }} {{ $t("COMMON.$") }}</span>
                    </div>
                    <div class="kt-widget__contact cursor-default" v-if="false">
                      <span class="kt-widget__label"></span>
                      <span class="kt-widget__data"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </portlet>
      </b-col>
    </b-row>
      <paginate :implement-search="false" :meta="statisticsUsersMeta" @refresh="onRefreshUsers" @change-page="onChangeUsersPage" />
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import ArticleStatistics from "@v@/partials/widgets/ArticleStatistics";
  import articleMixin from "@m@/article.mixin";
  import {mapGetters} from "vuex";
  import Paginate from "@v@/components/paginate/index";
  export default {
    name: "index",
    components: {Paginate, Portlet, ArticleStatistics},
    props: {
      id: {
        type: [Number, String]
      }
    },
    computed: {
      ...mapGetters(['statisticsUsers', 'statisticsUsersMeta']),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        basicQuantityLoading: true,
        basicQuantity: {},
        usersLoading: true,
      }
    },
    mixins: [articleMixin],
    created() {
      this.getBasicQuantity();
      this.getUsers();
    },
    mounted() {
      this.setBeadCrumbs();
    },
    methods: {
      setBeadCrumbs(){
        BreadCrumbs.setBreadCrumb([]);
        BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE_STATISTICS.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'article.home'}});
        BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE_STATISTICS.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
      },
      onRefreshStatistics(){
        this.getBasicQuantity()
      },
      getBasicQuantity(){
        this.basicQuantityLoading = true;
        this.getArticleBasicQuantity(this.id)
          .then(res=>{
            this.basicQuantity = res.data;
          }).finally(()=>{
            this.basicQuantityLoading = false
          })
      },
      getUsers(page = 1, showLoading = true){
        if( showLoading ){
          this.usersLoading = true
        }
        this.getArticleStatisticsUsers(this.id, page)
          .finally(()=>{
            if( showLoading ){
              this.usersLoading = false
            }
          })
      },
      onRefreshUsers(e){
        this.getUsers();
      },
      onChangeUsersPage(e){
        const { page } = e;
        this.getUsers(page);
      },
    }
  }
</script>

<style scoped>

</style>
