<template>
  <div class="raise-status">
    <base-modal :modal-busy="busy" :id="id" :title="title || $t('COMPONENTS.MODALS.RAISE.STATUS.TITLE')" :size="size" v-model="modalState" >
      <b-row class="form-group">
        <b-col sm="6" :md="index === 2 ? 12 : 6" v-for="(option, index) in options" :key="option.id" class="d-flex">
          <app-option :form-value="formData.status" :name="name" :option="option" @change="onChangeStatus" />
        </b-col>
      </b-row>
      <template v-if="formData.status > 1">
        <b-row>
          <b-col sm="6" md="12">
            <b-form-group id="input-group-target_id" :label="$t('COMPONENTS.MODALS.RAISE.STATUS.LABEL.target_id')" label-for="target_id">
              <b-input-group>
                <b-form-input readonly :value="targetText" :placeholder="$t('COMPONENTS.MODALS.RAISE.STATUS.PLACEHOLDER.target_id')"></b-form-input>
                <b-input-group-append>
                  <b-button-group>
                    <b-button id="target_id" @click="targetModalState = true">{{ $t('COMPONENTS.MODALS.RAISE.STATUS.BUTTON.CHOOSE') }}</b-button>
                    <b-button @click="onClickClearTarget">{{ $t('COMPONENTS.MODALS.RAISE.STATUS.BUTTON.CLEAR') }}</b-button>
                  </b-button-group>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="6" md="12">
            <b-form-group id="input-group-reason-ug" :label="$t('COMPONENTS.MODALS.RAISE.STATUS.LABEL.reason')" label-for="reason-ug">
              <b-form-textarea :state="validateState('zh-UG', 'status_reason')" aria-describedby="reason_ug-live-feedback" id="reason-ug" class="direction-rtl" v-model="formData.status_reason['zh-UG']" :placeholder="$t('COMPONENTS.MODALS.RAISE.STATUS.PLACEHOLDER.reason_ug')" />
              <b-form-invalid-feedback id="reason_ug-live-feedback">
                {{ $t('COMPONENTS.MODALS.RAISE.STATUS.FEEDBACK.reason_ug') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" md="12">
            <b-form-group id="input-group-reason-cn" :label="$t('COMPONENTS.MODALS.RAISE.STATUS.LABEL.reason')" label-for="reason-cn">
              <b-form-textarea :state="validateState('zh-CN', 'status_reason')" aria-describedby="reason_cn-live-feedback" id="reason-cn" class="direction-ltr" v-model="formData.status_reason['zh-CN']" :placeholder="$t('COMPONENTS.MODALS.RAISE.STATUS.PLACEHOLDER.reason_cn')" />
              <b-form-invalid-feedback id="reason_cn-live-feedback">
                {{ $t('COMPONENTS.MODALS.RAISE.STATUS.FEEDBACK.reason_cn') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <b-row class="mt-4">
        <b-col sm="6" md="12" class="d-flex gap-10">
          <b-button variant="primary" @click="onClickSubmit">ساقلاش</b-button>
        </b-col>
      </b-row>
    </base-modal>
    <choose-article-modal :title="$t('COMPONENTS.MODALS.RAISE.STATUS.LABEL.articleModalTitle')" v-if="formData.status > 1" v-model="targetModalState" @change="onChangeTarget" :title-field="`raise.title.${$i18n.locale}.title`" image-field="cover_image_preview" :form-value="formData.target_id" />
  </div>
</template>

<script>
  import baseModal from "./base.modal";
  import AppOption from "../AppOption";
  import articleMixin from "@m@/article.mixin";
  import ChooseArticleModal from "./choose.article.modal";
  import {mapGetters} from "vuex"
  import { validationMixin } from "vuelidate";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { required, requiredIf } from "vuelidate/lib/validators";
  export default {
    name: "RaiseStatusModal",
    components: { baseModal, AppOption, ChooseArticleModal },
    mixins: [articleMixin, validationMixin, validateStateMixin],
    props: {
      id: {
        type: String,
        default: () => 'raise-status-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      name: {
        type: String,
        default: ()=> 'o-option'
      },
      article: {
        type: Object,
        default: ()=>{
          return {};
        }
      }
    },
    computed: {
      ...mapGetters(['articles']),
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      article:{
        handler(v){
          if(v && v.hasOwnProperty('id')){
            const { raise: {jump_target = {}} } = v;
            if( jump_target && jump_target.hasOwnProperty('id')){
              this.targetText = this.getTitle(jump_target);
            }
          }
        },
        immediate: true
      },
      'article.raise.status': {
        handler(v){
          // console.info('article.raise.status: ', v)
          if(v){
            this.formData.status = v;
          }
        },
        immediate: true
      },
      'article.raise.status_reason': {
        handler(v){
          if( v ){
            this.formData.status_reason = v || {'zh-UG': null, 'zh-CN': null};
          }
        }
      }
    },
    validations(){
      let validates = {
        status_reason: {
          'zh-UG': {
            required: requiredIf(()=>{
              return !!this.formData.status_reason['zh-CN']
            })
          },
          'zh-CN': {
            required: requiredIf(()=>{
              return !!this.formData.status_reason['zh-UG']
            })
          }
        }
      };

      return {
        formData: validates
      }
    },
    data(){
      return {
        targetText: '',
        form: 0,
        busy: false,
        targetModalState: false,
        options: [
          {
            title: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.ONE.title"),
            value: '1',
            describe: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.ONE.describe"),
            id: 1
          },
          {
            title: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.TWO.title"),
            value: '2',
            describe: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.TWO.describe"),
            id: 2
          },
          {
            title: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.THREE.title"),
            value: '3',
            describe: this.$t("COMPONENTS.MODALS.RAISE.STATUS.OPTIONS.THREE.describe"),
            id: 3
          }
        ],
        formData: {
          status: '1',
          status_reason: {
            'zh-UG': null,
            'zh-CN': null
          },
          target_id: null,
          version: 1
        }
      };
    },
    methods: {
      onChangeStatus(e){
        const { version } = this.article || {};
        this.formData.version = version;
        this.formData.status = e;
      },
      onClickClearTarget(){
        this.formData.target_id = null;
        this.targetText = '';
      },
      onClickSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return false
        }
        this.busy = true;
        const { id } = this.article || {};
        let { formData } = this;
        this.setArticleStatus(id, formData)
          .then(()=>{
            this.$nextTick(()=>{
              this.$emit('success');
            });
          })
          .catch(e=>{
            this.$nextTick(()=>{
              this.$emit('fail', e);
            });
          })
          .finally(()=>{
            this.busy = false;
          })
      },
      onChangeTarget(v){
        this.formData.target_id = v;
        const { articles } = this;
        // console.info('onChangeTarget.articles', articles)
        const article = articles.filter(article=> article.id == v)[0];
        // console.info('onChangeTarget.article', article)
        this.targetText = this.getTitle(article);
        this.$nextTick(()=>{
          this.targetModalState = false
        })
      },
      getTitle(article){
        let { locale } = this.$i18n;
        const { raise: {title}} = article;
        if( title[locale] ){
          return title[locale].title;
        }
        return ''
      },
    }
  }
</script>

<style scoped>

</style>
